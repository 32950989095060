import React, {useEffect, useRef, useState, memo} from 'react';
import * as echarts from 'echarts';
import styles from './index.module.scss';
import {Image, Progress, Spin} from 'antd';
import classnames from "classnames";
import {Exchange, ShowDepth} from "./Tracking";
import binance from "../../Home/images/binance.svg"
import bitget from "../../Home/images/bitget.svg"
import gate from "../../Home/images/gate.io.svg"
import kucoin from "../../Home/images/kucoin.svg"
import lbank from "../../Home/images/lbank.svg"
import mexc from "../../Home/images/mexc.svg"
import uniswap from "../../Home/images/uniswap.svg"
import upbit from "../../Home/images/upbit.svg"
import {getRequest} from "../../helper/request";
import {formatNumber} from "../../utils/format";
const imgs:ExchangeMap = {
    binance,
    bitget,
    gate,
    kucoin,
    lbank,
    mexc,
    uniswap,
    upbit,
  }
  interface ExchangeMap {
    [key: string]: string;
  }
interface Depth{
    asks: string[][],
    bids: string[][]
}
class Ticket{
    logo: string = '';
    name: string = '';
    lastPrice: number = 0;
    priceChangePercent: number = 0;
    volume: number = 0;
    quoteVolume: number = 0;
    highPrice: number = 0;
    lowPrice: number = 0;
    high7dPrice: number = 0;
    low7dPrice: number = 0;
}
interface Bank{
    symbol: string;
    ticker: BankTicket;
    timestamp: string;
}
interface BankTicket{
    change: string;
    high: string;
    latest: string;
    low: string;
    turnover: string;
    vol: string;
}
interface Props{
    currSlider?: number;
    isShowPrice?: boolean;
    isShowVolume?: boolean;
    selectCoin: Exchange;
    page?: string;
    width?: number;
    height?: number
}
const TrackingChart = ({ currSlider, isShowPrice = true, isShowVolume = true, selectCoin, page, width, height}: Props) => {
    const [activeChart, setActiveChart] = useState('1d');
    const [ticket, setTicket] = useState<Ticket>({} as Ticket);
    const [bankTicket, setBankTicket] = useState<Bank>({} as Bank);
    const [depth, setDepth] = useState<ShowDepth[]>([]);
    const [priceData, setPriceData] = useState<number[][]>([]);
    const [volumeData, setVolumeData] = useState<number[][]>([]);
    const [maxDepth, setMaxDepth] = useState(0);
    const prevExchangeName = useRef('');
    const prevName = useRef('');
    const chartRef = useRef(null);
    const isHome = page === 'home';
    const nameObj: any = {
        binance: 'Binance',
        mexc: 'MEXC',
        lbank: 'LBank',
    }
    useEffect(() => {
        if ((prevExchangeName.current === selectCoin.exchangeName && selectCoin.name === prevName.current) || !selectCoin.exchangeName) {
            return
        }
        prevName.current = selectCoin.name;
        prevExchangeName.current = selectCoin.exchangeName;
        getTickerRequest();
    }, [selectCoin.exchangeName, selectCoin.name]);
    useEffect(() => {
        if(!depth) return;
        const maxDepth = Math.max(...depth.map(item=> Number(item.value)));
        setMaxDepth(maxDepth);
    }, [depth]);
    useEffect(() => {
        if (!selectCoin.exchangeName) {
            return
        }
        if (chartRef.current) {
            // 销毁已有的图表实例
            echarts.dispose(chartRef.current);
          }
        echartsHandle();
    }, [width, isShowPrice, isShowVolume, currSlider]);

    const handleFilterArr = (arr: number[][]): number[][] => {
        const newArr: number[] = [];
        const newArr1: number[] = [];
        arr.forEach(item => {
            if (item.length !== 2) {
                newArr.push(0)
                newArr1.push(0)
                return
            }
            newArr.push(item[0])
            newArr1.push(item[1])
        })
        return [newArr, newArr1]
    }
    const echartsHandle = (priceArr?: number[][], volumeArr?: number[][]) => {
        setTimeout(() => {
            try {
                const chartInstance = echarts.init(chartRef.current);
                const price = {
                    yAxisIndex: 0,
                    name: 'Price',
                    type: 'line',
                    symbol: 'none',
                    sampling: 'lttb',
                    itemStyle: {
                        color: 'rgba(255, 194, 74, 1)'
                    },
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(255, 194, 74, 0.1)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(255, 194, 74, 0.1)'
                            }
                        ])
                    },
                    data: handleFilterArr(priceArr || priceData)[1]
                };
                const volume = {
                    yAxisIndex: 1,
                    name: 'Volume',
                    type: 'line',
                    symbol: 'none',
                    sampling: 'lttb',
                    itemStyle: {
                        color: '#11D49D'
                    },
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(17, 212, 157, 0.1)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(17, 212, 157, 0.1)'
                            }
                        ])
                    },
                    data: handleFilterArr(volumeArr || volumeData)[1]
                };
                let series = [ price, volume ];
                const arr = handleFilterArr(priceArr || priceData)
                const options = {
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c}'
                    },
                    legend: {
                        left: isHome ? 44 : 20,
                        top: isHome ? 18 : 30,
                        textStyle: {
                            fontSize: isHome ? 14 : 8
                        },
                        selected: {
                            'Price': isShowPrice,
                            'Volume': isShowVolume
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: handleFilterArr(priceArr || priceData)[0].map(it => {
                            const date = new Date(it)
                            return date.getDate()
                        })
                    },
                    yAxis: [
                      {
                        type: 'value',
                        boundaryGap: [0, '100%'],
                        axisLabel: {
                            formatter: '{value}%',
                            fontSize: isHome ? 14 : 10
                        },
                        minInterval: 2
                      },
                      {
                        type: 'value',
                        position: 'right',
                        axisLabel: {
                            formatter: '{value}M',
                            fontSize: isHome ? 12 : 8
                        },
                      }
                    ],
                    dataZoom: [
                        {
                            type: 'inside',
                            start: 0,
                            end: 100,
                            show: false
                        },
                        {
                            type: 'slider',
                            start: 0,
                            end: 100,
                            show: false
                        }
                    ],
                    series: series
                };
                chartInstance.setOption(options);
                // 销毁图表，防止内存泄漏
                return () => {
                    chartInstance.dispose();
                };
            } catch (e) {
                console.log(e)
            }
        }, 300)
    }
    const getTickerRequest =  () => {
        getRequest('/exchange/ticker/24hr', { params: { exchange: selectCoin.exchangeName, symbol: selectCoin.pair } })
            .then( res => {
                const data = res[0] as unknown as Ticket;
                if(selectCoin.exchangeName === 'lbank') {
                    const data = res[0] as unknown as Bank;
                    setBankTicket(data);
                    getDepthRequest(+data.ticker.latest);
                } else {
                    setTicket(data);
                    getDepthRequest(data.lastPrice);
                }
                if(data !== null) {
                    get7dPriceRequest(data);
                }
            })
            .catch(error => {
                console.error('Error fetching data', error);
            });
    }
    const getDepthRequest = (lastPrice: number) => {
        getRequest('/exchange/depth', {params: {exchange: selectCoin?.exchangeName, symbol: selectCoin?.pair}})
            .then(res => {
                const data = res[0] as unknown as Depth
                const twoAsks = getAsksData(lastPrice, data, 1.02);
                const fiveAsks = getAsksData(lastPrice, data, 1.05);
                const tenAsks = getAsksData(lastPrice, data, 1.1);
                const twoBids = getBidsData(lastPrice, data, 0.98);
                const fiveBids = getBidsData(lastPrice, data, 0.95);
                const tenBids = getBidsData(lastPrice, data, 0.90);
                setDepth([
                    {type: 'asks', label: '+10%', value: tenAsks},
                    {type: 'asks', label: '+5%', value: fiveAsks},
                    {type: 'asks', label: '+2%', value: twoAsks},
                    {type: 'bids', label: '-2%', value: twoBids},
                    {type: 'bids', label: '-5%', value: fiveBids},
                    {type: 'bids', label: '-10%', value: tenBids}]);
            })
            .catch(error => {
                getDepthRequest(lastPrice);
                console.error('Error fetching data:', error);
            })
    }
    const getAsksData = (lastPrice: number, data: Depth, val: number) => {
        const twoMaxPrice : number = lastPrice * val;
        const twoAsks: string[][] = data.asks && data.asks.filter((item: string[]) : boolean => twoMaxPrice > Number(item[0]));
        return twoAsks.reduce((prev : number, curr : string[], index: number )=> prev + Number(curr[0]) * Number(curr[1]), 0);
    }
    const getBidsData = (lastPrice: number, data: Depth, val: number) => {
        const twoMaxPrice : number = lastPrice * val;
        const twoAsks: string[][] = data.bids && data.bids.filter((item: string[]) : boolean => twoMaxPrice < Number(item[0]));
        return twoAsks.reduce((prev : number, curr : string[], index: number )=> prev + Number(curr[0]) * Number(curr[1]), 0);
    }
    const get7dPriceRequest = (ticket: Ticket, interval:string = '1d') => {
        const timestamp = Math.floor(new Date().getTime()/1000) - (6 * 86400)
        getRequest('/exchange/klines', {params: {exchange: selectCoin?.exchangeName, symbol: selectCoin?.pair, interval: interval, timestamp: timestamp}})
            .then(res => {
                const data = res[0] as unknown as number[][];
                const height = Math.max(...data.map(item=> Number(item[2])));
                const low = Math.min(...data.map(item=> Number(item[3])));

                const priceData = data.map((item, index) => {
                    return [Number(item[0]), (+(Number(item[4])/data[index <= 0 ? 0 : (index - 1)][4]).toFixed(2) - 1) * 100]
                });
                setPriceData(priceData);
                let volumeData: number[][] = [];
                if(selectCoin.exchangeName !== 'lbank') {
                    volumeData = data.map((item, index) => [Number(item[0]),Number(item[7])/1000000]);
                } else {
                    volumeData = data.map((item, index) => [Number(item[0]),(Number(item[4]) * Number(item[5]))/1000000]);
                }
                setVolumeData(volumeData);
                let ticketTemp = {...ticket as Ticket}
                ticketTemp.high7dPrice = height;
                ticketTemp.low7dPrice = low;
                setTicket(ticketTemp);
                echartsHandle(priceData, volumeData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    const activeChartHandle = (str: string) => {
        setActiveChart(str)
        get7dPriceRequest(ticket, str);
    }
    function getFormatVal(val: number) {
        return Number(Math.floor(val)).toLocaleString('en');
      }
    function getFormatVal2(val: number) {
        if(val > 1000) {
            return Number(Number(val).toFixed(2)).toLocaleString('en');
        }
        return Number(val).toFixed(val > 0 ? 2 : 3);
    }
      const isShowContent = () => {
        if(ticket && ticket.lastPrice && depth.length > 0 && selectCoin.exchangeName) {
            return true;
        }
        if(bankTicket && bankTicket.ticker && bankTicket.ticker.latest && depth.length > 0 && selectCoin.exchangeName) {
            return true;
        }
        return false;
      }
      const formatPriceNumber = (price: number) => {
        if(price > 10000) {
            return getFormatVal(price);
        }
        if(price > 1) {
            return Number(Number(price).toFixed(2)).toLocaleString('en');
        }
        if(price > 0.001) {
            return Number(price).toFixed(3);
        }
        return formatNumber(Number(price).toFixed(4).toString());
      }
    return (
        <div className={classnames(styles.echartsContainer, isHome && styles.homeEchartsContainer)}
             style={{width: width + 'px', height: height + 'px', marginTop: height === 230 ? '24px' : isHome ? "6px" : "0"}}>
            {selectCoin && !selectCoin.name && <div className={styles.chartNoData}>Complete configuration to see a preview.</div>}
            {(!depth || depth.length === 0) && selectCoin.exchangeName && <div className={styles.spinWrap}><Spin tip="Loading..." size="large" /></div>}
            {
                isShowContent() && <div className={styles.mainWrap}>
                    <div className={styles.coinInfo}>
                        <div className={classnames(styles.logoWrap, isHome && styles.homeLogoWrap)}>
                            {selectCoin.logo && <Image preview={false} className={styles.logoImg} src={selectCoin.logo}></Image>}
                        </div>
                        <div className={styles.infoWrap}>
                            <div className={classnames(styles.coinName, isHome && styles.homeCoinName)}>{selectCoin.name}</div>
                            <div className={classnames(styles.coinMoney, isHome && styles.homeCoinMoney)}>
                                <div className={classnames(styles.money, isHome && styles.homeMoney)}>
                                    ${(ticket && ticket.lastPrice && formatPriceNumber(+ticket.lastPrice)) || bankTicket && bankTicket.ticker && formatPriceNumber(+bankTicket.ticker.latest)}
                                </div>
                                <div className={classnames(styles.rate, isHome && styles.homeRate)}>
                                    {((ticket?.priceChangePercent > 0) || (+bankTicket?.ticker?.change > 0)) && '+'}
                                    <span className={(+ticket?.priceChangePercent || +bankTicket?.ticker?.change) > 0 ? styles.up : styles.down}>
                                        {(ticket?.priceChangePercent && Number(ticket.priceChangePercent).toFixed(2)) || bankTicket?.ticker &&
                                        Number(bankTicket.ticker.change).toFixed(2)}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightData}>
                        <div className={classnames(styles.btnWrap, isHome && styles.homeBtnWrap)}>
                            {selectCoin.exchangeName && <Image preview={false} className={classnames(styles.btnIcon, isHome && styles.homeBtnIcon)} src={imgs[selectCoin.exchangeName.toLowerCase()]}></Image>}
                            <div className={classnames(styles.btnText, isHome && styles.homeBtnText)}>{nameObj[selectCoin.exchangeName]}</div>
                        </div>
                        <div className={styles.rightList}>
                            <div className={classnames(styles.rightItem, isHome && styles.homeRightItem)}>
                                <div className={classnames(styles.rightTitle, isHome && styles.homeRightTitle)}>Volunme(24H)</div>
                                <div className={classnames(styles.rightContent, isHome && styles.homeRightContent)}>
                                    ${(ticket && ticket.quoteVolume && getFormatVal(ticket.quoteVolume)) || bankTicket && bankTicket.ticker && getFormatVal(+bankTicket.ticker.vol)}
                                </div>
                            </div>
                            <div style={{marginLeft: width !== 538 ? '30px' : "8px"}} className={classnames(styles.rightItem, isHome && styles.homeRightItem)}>
                                <div className={classnames(styles.rightTitle, isHome && styles.homeRightTitle)}>Low / High(24H)</div>
                                <div className={classnames(styles.rightContent, isHome && styles.homeRightContent)}>
                                    ${(ticket && ticket.highPrice && getFormatVal2(ticket.highPrice)) || bankTicket && bankTicket.ticker && getFormatVal2(+bankTicket.ticker.high)}
                                    / ${(ticket && ticket.lowPrice && getFormatVal2(ticket.lowPrice)) || bankTicket && bankTicket.ticker && getFormatVal2(+bankTicket.ticker.low)}
                                </div>
                            </div>
                            <div style={{marginLeft: width !== 538 ? '30px' : "8px"}} className={classnames(styles.rightItem, isHome && styles.homeRightItem)}>
                                <div className={classnames(styles.rightTitle, isHome && styles.homeRightTitle)}>Low / High(7D)</div>
                                <div className={classnames(styles.rightContent, isHome && styles.homeRightContent)}>
                                    ${ticket && getFormatVal2(ticket.high7dPrice)}
                                    / ${ticket && getFormatVal2(ticket.low7dPrice)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className={styles.chartProgressWrap} style={{opacity: depth && depth.length > 0 && selectCoin.exchangeName ? 1 : 0}}>
                <div
                    className={classnames(styles.chartWrap, isHome && styles.homeChartWrap)}
                    ref={chartRef}
                >
                </div>
                {!isHome && <div className={styles.chartTimeWrap}>
                    <div
                        className={classnames(styles.chartTimeItem, activeChart === '1d' && styles.activeChartTimeItem)}
                        onClick={() => activeChartHandle('1d')}>1D
                    </div>
                    <div
                        className={classnames(styles.chartTimeItem, activeChart === '1h' && styles.activeChartTimeItem)}
                        onClick={() => activeChartHandle('1h')}>1H
                    </div>
                    {/*<div className={classnames(styles.chartTimeItem, activeChart === 2 && styles.activeChartTimeItem)}*/}
                    {/*     onClick={() => activeChartHandle(2)}>1M</div>*/}
                </div>}
                <div className={classnames(styles.progressWrap, isHome && styles.homeProgressWrap)}>
                    {depth && depth.length > 0 && <div
                        className={classnames(styles.progressTitle, isHome && styles.homeProgressTitle)}>Depth</div>}
                    <div className={styles.progressList}>
                        {
                            depth && depth.map((item: ShowDepth, index: number) => (
                                <div className={classnames(styles.progressItem, isHome && styles.homeProgressItem)}
                                     key={index}>
                                    <div className={classnames(styles.progressStatus, isHome && styles.homeProgressStatus,
                                        item.type === 'bids' && styles.bidsProgressStatus)}>{item.label}</div>
                                    <div className={styles.progressImgWrap}>
                                        <Progress size={'small'} strokeColor={item.type === 'bids' ? 'rgba(249, 52, 53, 1)' : 'rgba(35, 196, 72, 1)'}
                                                  percent={(item.value/maxDepth) * 100}  trailColor={'#ffffff'} showInfo={false} />
                                    </div>
                                    <div className={classnames(styles.progressMoney, isHome && styles.homeProgressMoney)}>${getFormatVal2(item.value)}</div>
                                </div>
                                ))
                        }

                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(TrackingChart);