import {useState, useRef, useEffect} from "react";
import * as echarts from 'echarts';
import styles from "./index.module.scss"

const Chart = () => {
    const chartRef = useRef(null);

    useEffect(() => {
        chartHandle();
    }, [])

    const chartHandle = () => {
        const chartInstance = echarts.init(chartRef.current);
        const option = {
            tooltip: {
                show: false
            },
            legend: {
                // show: false
                bottom: 0,
                left: 'center'
            },
            series: [{
                name: 'bots',
                type: 'pie',
                radius: ['40%', '50%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    fontSize: 16,
                    fontWeight: 'bold',
                    position: 'center'
                },
                emphasis: {
                    scale: false,
                    label: {
                        show: true,
                        fontSize: 16,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    { value: 1048, name: 'Search' },
                    { value: 735, name: 'Direct' },
                    { value: 580, name: 'Email' },
                    { value: 484, name: 'Union' },
                    { value: 300, name: 'Video' }
                ],
            }]
        };

        option && chartInstance.setOption(option);
    } 
    return (
        <div >
           <div className={styles.chartWrap} ref={chartRef} /> 
        </div>
    );
};

export default Chart;