import styles from "./index.module.scss";
import {Modal} from "antd";
import {forwardRef, useImperativeHandle, useState} from "react";

interface Props {}
const BotModal = forwardRef((props: Props, ref) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState<string>("")
  const [value, setValue] = useState<string>("")

  const showModal = (title: string, value:string) => {
    setIsModalOpen(true);
    setTitle(title)
    setValue(value)
  };
  useImperativeHandle(ref, () => ({
    showModal,
  }));


  const onCancel = () => {
    setIsModalOpen(false);
  }
  const handleOk = () => {
    onCancel()
  }
  return (
    <Modal
      open={isModalOpen}
      centered
      getContainer={() => document.body}
      onCancel={onCancel}
      closable={false}
      maskClosable={false}
      zIndex={9999}
      footer={null}>
      <div className={styles.bot_delete_wrap}>
        <p className={styles.delete_tips}>Delete subscription</p>
        <div className={styles.delete_card}>
          <p className={styles.title}>{title}</p>
          <p className={styles.value}>{value}</p>
        </div>
        <div className={styles.delete_btn_wrap}>
          <div className={styles.delete_cancel} onClick={onCancel}>Cancel</div>
          <div className={styles.delete_confirm} onClick={handleOk}>Confirm</div>
        </div>
      </div>
    </Modal>
  )
})

export default BotModal