import styles from "./index.module.scss"
import * as echarts from 'echarts';
import {RefObject, useEffect, useRef, useState} from "react";
import classnames from "classnames";
import {LinesType} from "../../index"
const Index = (props: {priceData: number[][], chartTimeHandle: (time: string) => void}) => {
  const [active, setActive] = useState<string>('1d');
  const char = useRef(null)
  useEffect(() => {
    if (char.current) {
      echarts.dispose(char.current);
    }
    initEchart()
  }, [props.priceData])
  const initEchart = () => {
    console.log(props.priceData)
    const chart = echarts.init(char.current)
    let options = {
      title: {
        text: ''
      },
      tooltip: {
        trigger: 'item',
        formatter: function(params: any) {
          return `
                <div style="background-color: rgba(255, 255, 255, 0.9);">
                  <p>${formattedDate(params.data[0])}</p>
                  <p>Price: $ ${(+params.data[1]).toFixed(2)} </p>
                  <p>Volume: $ ${params.data[2]}m</p>
                </div>
              `;
        }
      },
      grid: {
        left: '-3%',
        right: '0%',
        bottom: '-3%',
        containLabel: true
      },
      xAxis: {
        type: 'time',
        boundaryGap: false
      },
      yAxis: [
        {
          show: false,
          type: 'value'
        }
      ],
      series: [
        {
          name: 'Price',
          type: 'line',
          stack: 'Total',
          lineStyle: {
            color: 'rgba(17, 212, 157, 1)', // 设置折线颜色
          },
          itemStyle: {
            color: 'rgba(17, 212, 157, 1)', // 设置折线上点的颜色
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(17, 212, 157, 0.10)', // 起始颜色
                },
                {
                  offset: 1,
                  color: 'rgba(17, 212, 157, 0)', // 结束颜色
                },
              ],
            },
          },
          emphasis: {
            focus: 'series'
          },
          data: props.priceData
        }
      ]
    }

    options && chart.setOption(options)
  }

  const formattedDate = (timestamp: number) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份是从 0 开始的，需要加 1
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const chartTimeHandle = (type: string) => {
    setActive(type);
    props.chartTimeHandle(type)
  }
  return <div className={styles.wrap_line}>
    <div className={styles.menu}>
      <div className={styles.menu_left}>
        <div className={classnames([styles.menu_left_item, styles.menu_active])}>Price</div>
        <div className={styles.menu_left_item}>TVL</div>
        <div className={styles.menu_left_item}>Unlocked</div>
      </div>
      <div className={styles.menu_right}>
        <div className={classnames([styles.menu_right_item, active === '1d' && styles.menu_right_active])} onClick={() => chartTimeHandle('1d')}>1D</div>
        <div className={classnames([styles.menu_right_item, active === '1w' && styles.menu_right_active])} onClick={() => chartTimeHandle('1w')}>1W</div>
        <div className={classnames([styles.menu_right_item, active === '1m' && styles.menu_right_active])} onClick={() => chartTimeHandle('1m')}>1M</div>
      </div>
    </div>
    <div className={styles.charts} ref={char}></div>
  </div>
}

export default Index