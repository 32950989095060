import styles from "./index.module.scss"
import logo from "./logo.png"
import telegram from "./telegram.png"
import twitter from "./twitter.png"
import icon1 from "./icon1.png"
import icon2 from "./icon2.png"
import icon3 from "./icon3.png"
import icon4 from "./icon4.png"
import icon5 from "./icon5.png"
import {useNavigate} from "react-router-dom";
const Footer = () => {
    const navigator = useNavigate()
    const handleClick = (key: string) => {
        const map = new Map([
            ['dashboard', () => navigator('/dashboard', {state: {active: 'dashboard'}})],
            ['bots', () => navigator('/bots', {state: {active: 'bots'}})],
            ['telegram', () => window.open('https://t.me/dexbrowserofficial')],
            ['twitter', () => window.open('https://twitter.com/dexbrowser')],
            ['facebook', () => window.open('https://www.facebook.com/dexbrowser')],
            ['invite', () => window.open('https://discord.com/invite/k5TPsaVFZG')],
            ['medium', () => window.open('https://dexbrowser.medium.com/')],
            ['company', () => window.open('https://www.linkedin.com/company/dexbrowser/')],
            ['docs', () => window.open('https://dexbrowser.medium.com/')],
            ['github', () => window.open('https://github.com/DexBro')],
        ])
        if (!map.has(key)) {
            return
        }
        map.get(key)!()
    }

    return <div className={styles.footer_wrap}>
        <div className={styles.footer_left}>
            <img src={logo} alt="" className={styles.footer_logo} />
            <div className={styles.footer_left_bottom}>
                <div className={styles.footer_left_bottom_top}>
                    <img src={telegram} onClick={() => handleClick('telegram')} alt=""/>
                    <img src={twitter} onClick={() => handleClick('twitter')} alt=""/>
                    <img src={icon1} onClick={() => handleClick('facebook')} alt=""/>
                    <img src={icon2} onClick={() => handleClick('invite')} alt=""/>
                    <img src={icon3} onClick={() => handleClick('medium')} alt=""/>
                    <img src={icon4} onClick={() => handleClick('company')} alt=""/>
                    <a href="mailto:contact@dexbrowser.com">
                        <img src={icon5} onClick={() => handleClick('contact')} alt=""/>
                    </a>
                </div>
                <div className={styles.footer_left_bottom_bot}>© 2023 DEX Browser Ltd.</div>
            </div>
        </div>
        <div className={styles.footer_right}>
            <div className={styles.footer_right_item}>
                <div className={styles.footer_item_top}>ABOUT</div>
                <div className={styles.footer_list_child}>What is DEX Browser?</div>
                <div className={styles.footer_list_child}>Terms</div>
            </div>
            <div className={styles.footer_right_item}>
                <div className={styles.footer_item_top}>PRODUCTS</div>
                <div className={styles.footer_list_child} onClick={() => handleClick('dashboard')}>Dashboard</div>
                <div className={styles.footer_list_child}>Analysis market</div>
                <div className={styles.footer_list_child}>Market maker tools</div>
                {/* <div className={styles.footer_list_child} onClick={() => handleClick('bots')}>Bots</div> */}
            </div>
            <div className={styles.footer_right_item}>
                <div className={styles.footer_item_top}>DEVELOPERS</div>
                <div className={styles.footer_list_child} onClick={() => handleClick('docs')}>Docs</div>
                <div className={styles.footer_list_child} onClick={() => handleClick('github')}>Github</div>
                <div className={styles.footer_list_child}>Tutorials</div>
            </div>
        </div>
    </div>
}

export default Footer