import styles from './index.module.scss';
import React from "react";
import search from "./image/search.png";
import {Image} from "antd";
import unit1 from './image/unit1.png';
import unit2 from './image/unit2.png';
import unit3 from './image/unit3.png';
import unit4 from './image/unit4.png';
export enum UnitType {
    Add = 'add',
    Unit = 'unit',
    Tracking = 'tokenTracking',
    Allocations = 'allocations',
    TokenPrice = 'tokenPrice'
}
const SelectUnit = (props: {clickUnit: (tracking: UnitType) => void}) => {
    return (
        <div>
            <div className={styles.title}>SELECT A UNIT</div>
            <div className={styles.tips}>Displays the latest market data for a coin in real-time</div>
            <div className={styles.list}>
                <div className={styles.item} onClick={() => props.clickUnit(UnitType.Tracking)}>
                    <Image preview={false} className={styles.unitImg} src={unit1}></Image>
                </div>
                <div className={styles.item} onClick={() => props.clickUnit(UnitType.TokenPrice)}>
                    <Image preview={false} className={styles.unitImg} src={unit4}></Image>
                </div>
                {/*<div className={styles.item} onClick={() => props.clickUnit(UnitType.Allocations)}>*/}
                {/*    <Image preview={false} className={styles.unitImg} src={unit2}></Image>*/}
                {/*</div>*/}
                {/*<div className={styles.item}>*/}
                {/*    <Image preview={false} className={styles.unitImg} src={unit3}></Image>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default SelectUnit;