import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {baseUrl} from "../config";

const instance = axios.create({
    baseURL: baseUrl,
    timeout: 10000
});

// 请求拦截器
instance.interceptors.request.use(
    config => {
        config.headers.Authorization = 'bearer ' + localStorage.getItem('jwt');
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        return Promise.reject(error);
    }
);



export async function getRequest<T,K>(url:string, query?:AxiosRequestConfig, headers?: Record<string, string>):Promise<[AxiosResponse|null, null|Error]> {
    return new Promise(resolve => {
        instance.get(url, query).then(res => {
            resolve([res, null])
        })
        .catch(err => {
            resolve([null, new Error(err)])
        })
    })
}

export async function postRequest<T,K>(url:string, params?:T):Promise<[K|null, null|Error]> {
    return new Promise(resolve => {
        instance.post<T,K>(url, params).then(res => {
            resolve([res, null])
        })
        .catch(err => {
            resolve([null, new Error(err)])
        })
    })
}