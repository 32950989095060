import Header from "../components/header";
import React, {useState, useEffect, useRef} from "react";
import Footer from "../components/footer";
import styles from "./index.module.scss"
import website from "./images/website.png"
import telegram from "./images/telegram.png"
import twitter from "./images/twitter.png"
import redIcon from "./images/redIcon.png"
import greenIcon from "./images/greenIcon.png"
import upgradeIcon from "./images/upgrade.png"
import {Image, Progress, Tabs, TabsProps, Table} from "antd";
import classnames from "classnames";
import LineChart from "./components/LineChart";
import PieChart from "./components/PieChart"
import {useLocation} from "react-router-dom";
import {getRequest, postRequest} from "../helper/request";
import UpComing from "./components/Upcoming";
import binance from "../Home/images/binance.svg";
import bitget from "../Home/images/bitget.svg";
import gate from "../Home/images/gate.io.svg";
import kucoin from "../Home/images/kucoin.svg";
import lbank from "../Home/images/lbank.svg";
import mexc from "../Home/images/mexc.svg";
import uniswap from "../Home/images/uniswap.svg";
import upbit from "../Home/images/upbit.svg";
import {formatAmount} from "../utils/format"
// @ts-ignore
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {formatNumber} from "../utils/format";
import {useNavigate} from "react-router-dom";

const imgs:ExchangeMap = {
    binance,
    bitget,
    gate,
    kucoin,
    lbank,
    mexc,
    uniswap,
    upbit,
}
interface ExchangeMap {
    [key: string]: string;
}
const items: TabsProps['items'] = [
    {
        key: 'Overview',
        label: 'Overview',
        children: '',
    }, {
        key: 'Unlocks',
        label: 'Unlocks',
        children: '',
    }, {
        key: 'Market',
        label: 'Market',
        children: '',
    },
];

interface CoinType {
    categories: string[];
    circulatingSupply: string;
    description: string;
    fees: string;
    isNewListing: boolean;
    isPerpetual: boolean;
    listingTime: number;
    logo: string;
    maxSupply: string;
    name: string;
    price: string;
    priceChangePercent: number;
    quoteVolume: string;
    revenue: string;
    slug: string;
    symbol: string;
    totalRaised: string;
    totalValueLocked: string;
    treasury: string;
    volume: string;
}

interface Supply{
    circulatingSupply: string;
    currentPrice: string;
    fullyDilutedValuation: string;
    holders: number;
    infiniteSupply: boolean;
    marketCap: string;
    marketCapChange24H: string;
    marketCapChangePercentage24H: number;
    maxSupply: string;
    platform: string;
    priceChange24H: string;
    priceChangePercentage24H: number;
    timestamp: number;
    timestamp_v: string;
    totalSupply: string;
    totalVolume24H: string;
    transferQuoteVolume24H: string;
    transferVolume24H: string;
    transfers24H: number;
}

export interface Tickers{
    buyOrdersAmount: string;
    depthUsdNegativeFive: string;
    depthUsdNegativeTen: string;
    depthUsdNegativeTwo: string;
    depthUsdPositiveFive: string;
    depthUsdPositiveTen: string;
    depthUsdPositiveTwo: string;
    exchange: string;
    price: string;
    priceChangePercent: number;
    quoteVolume: string;
    sellOrdersAmount: string;
    timestamp: number;
    timestamp_v: string;
    volume: string;
}
export interface Progress {
    lockedAmount: string;
    slug: string;
    timestamp: number;
    timestamp_v: string;
    unlockedAmount: string;
    unscheduleLockedAmount: string;
    untrackedAmount: string;
}
interface Allocations {
    cliffUnlockAmount: string;
    color: string;
    linearUnlockAmountPerDay: string;
    linearUnlockDuration: number;
    name: string;
    standardName: string;
    type: string;
    unlockTime: number;
}
export interface EventType{
    cliffUnlockAmount: string;
    linearUnlockAmountPerDay: string;
    linearUnlockDuration: number;
    type: string;
    unlockTime: number;
    allocations: Allocations[]
}

interface LatestTickersType {
    categories: string[];
    description: string;
    fees: string;
    isNewListing: boolean;
    isPerpetual: boolean;
    listingTime: number;
    logo: string;
    exchange: string;
    price: string;
    priceChangePercent: number;
    quoteVolume: string;
    revenue: string;
    slug: string;
    symbol: string;
    totalRaised: string;
    totalValueLocked: string;
    treasury: string;
    volume: string;
    depthUsdNegativeTwo: string;
    depthUsdPositiveTwo: string;
    buyOrdersAmount: string;
    sellOrdersAmount: string;
}

export interface LinesType{
    closePrice: string;
    exchange: string;
    priceChangePercent1H: number;
    priceChangePercent7D: number;
    priceChangePercent24H: number;
    quoteVolume: string;
    quoteVolumeChangePercent1H: number;
    quoteVolumeChangePercent7D: number;
    quoteVolumeChangePercent24H: number;
    timestamp: number;
    timestamp_v: string;
    volume: string;
    volumeChangePercent1H: number;
    volumeChangePercent7D: number;
    volumeChangePercent24H: number;
}

interface Exchanges{
    isPerpetual: boolean;
    listingTime: number;
    name: string;
    pair: string;
}

const Detail = () => {
    const [activeTableNav, setActiveTableNav] = useState<'cex' | 'dex'>('cex')
    const location = useLocation();
    const [coin, setCoin] = useState({} as CoinType);
    const [supply, setSupply] = useState({} as Supply);
    const [tickers, setTickers] = useState<Tickers[]>([]);
    const [progress, setProgress] = useState({} as Progress);
    const [event, setEvent] = useState<EventType[]>([]);
    const [activeTab, setActiveTab] = useState<'Overview'|'Unlocks'|'Market'>('Overview')
    const Overview = useRef<HTMLDivElement>(null)
    const Unlock = useRef<HTMLDivElement>(null)
    const Markets = useRef<HTMLDivElement>(null)
    const [latestTickers, setLatestTickers] = useState<LatestTickersType[]>([]);
    const [latestLines, setLatestLines] = useState<LinesType[]>([]);
    const [exchanges, setExchanges] = useState<Exchanges[]>([]);
    const [priceData, setPriceData] = useState<number[][]>([]);
    const navigator = useNavigate()
    const settings = {
        dots: true, // 是否显示导航点
        infinite: false, // 是否循环滚动
        speed: 500, // 滚动速度
        slidesToShow: 3, // 一次显示的内容数量
        slidesToScroll: 1, // 每次滚动的内容数量
    };
    useEffect(() => {
        window.addEventListener('wheel', () => {
            const overview = Overview.current ? getDistanceToTop(Overview.current) : 0
            const unlock = Unlock.current ? getDistanceToTop(Unlock.current) : 0
            const markets = Markets.current ? getDistanceToTop(Markets.current) : 0
            if (overview >= 130 && overview <= 200) {
                setActiveTab('Overview')
                return
            }
            if (unlock >= 130 && unlock <= 200) {
                setActiveTab('Unlocks')
                return
            }
            if (markets >= 130 && markets <= 200) {
                setActiveTab('Market')
                return
            }
        })
    }, [])
    useEffect(() => {
        getDetailRequest();
        getLatestMarketsRequest();
        getTickersRequest();
        getProgressRequest();
        getEventsRequest();
        getLatestTickersRequest();
        getLatestKlinesRequest();
        getExchangesRequest();
    }, [])
    function getDistanceToTop(element: HTMLDivElement) {
        const rect = element.getBoundingClientRect();
        return rect.top;
    }
    const getDetailRequest = () => {
        getRequest(`/tokens/${location.state?.active}`)
            .then(res => {
                const data = res[0] as unknown as CoinType
                setCoin(data);
            })
            .catch(error => {
                console.error('Error fetching data', error);
            });
    }

    const getLatestMarketsRequest = () => {
        getRequest(`/tokens/${location.state?.active}/LatestMarkets`, {params: {platform: 'coingecko'}})
            .then(res => {
                // @ts-ignore
                const data = res[0][0] as unknown as Supply || {}
                if(data.infiniteSupply) {
                    data.maxSupply = '♾️';
                }
                setSupply(data)
            })
            .catch(error => {
                console.error('Error fetching data', error);
            });
    }

    const getTickersRequest = () => {
        postRequest(`/tokens/${location.state?.active}/tickers`)
            .then(res => {
                const data = res[0] as unknown as Tickers[]
                setTickers(data);
            })
            .catch(error => {
                console.error('Error fetching data', error);
            });
    }
    const getProgressRequest = () => {
        getRequest(`/tokens/${location.state?.active}/unlock/progress`)
            .then(res => {
                const data = res[0] as unknown as Progress || {}
                setProgress(data);
            })
            .catch(error => {
                console.error('Error fetching data', error);
            });
    }

    const getEventsRequest = () => {
        postRequest(`/tokens/${location.state?.active}/unlock/events`)
            .then(res => {
                const data = res[0] as unknown as EventType[];
                let newData: EventType[] = [];
                    data.map(item => {
                    let cliffArr: Allocations[] = [];
                    let linearArr: Allocations[] = [];
                    let cliffEvent: EventType = JSON.parse(JSON.stringify(item));
                    let linearEvent: EventType = JSON.parse(JSON.stringify(item));
                    item.allocations.map(it => {
                        if(it.type === 'cliff') {
                            cliffArr.push(it);
                        } else {
                            linearArr.push(it);
                        }
                    })
                    if(linearArr.length > 0) {
                        linearEvent.allocations = linearArr;
                        newData.push(linearEvent);
                    }
                    if(cliffArr.length > 0) {
                        cliffEvent.allocations = cliffArr;
                        newData.push(cliffEvent);
                    }
                })
                setEvent(newData);
            })
            .catch(error => {
                console.error('Error fetching data', error);
            });
    }
    const getLatestTickersRequest = () => {
        getRequest(`/tokens/${location.state?.active}/latestTickers`)
            .then(res => {
                const data = res[0] as unknown as LatestTickersType[]
                const lastData = data.filter(item => item.exchange !== 'ALL')
                setLatestTickers(lastData);
            })
            .catch(error => {
                console.error('Error fetching data', error);
            });
    }
    const getLatestKlinesRequest = () => {
        getRequest(`/tokens/${location.state?.active}/latestKlines`)
            .then(res => {
                const data = res[0] as unknown as LinesType[]
                setLatestLines(data);
            })
            .catch(error => {
                console.error('Error fetching data', error);
            });
    }
    const getKLinesRequest = (name: string, pair: string, time?: string) => {
        const day = time === '1d' || !time ? 1 : time === '1w' ? 6 : 30;
        console.log(time)
        const timestamp = Math.floor(new Date().getTime()/1000) - (day * 86400);
        getRequest(`/exchange/klines`, {params: {
                exchange: name,
                symbol: pair,
                interval: time === '1d' || !time ? '1h' : '1d',
                timestamp: timestamp
            }})
            .then(res => {
                const data = res[0] as unknown as number[][];
                const priceData = data.map((item, index) => {
                    return [Number(item[0]), (+(Number(item[4])/data[index <= 0 ? 0 : (index - 1)][4]).toFixed(2) - 1) * 100,
                        +(Number(item[7]) / 1000000).toFixed(2)]
                });
                setPriceData(priceData);
            })
            .catch(error => {
                console.error('Error fetching data', error);
            });
    }

    const getExchangesRequest = () => {
        getRequest(`/tokens/${location.state?.active}/exchanges`)
            .then(res => {
                const data = res[0] as unknown as Exchanges[];
                setExchanges(data);
                getKLinesRequest(data[0].name, data[0].pair);
            })
            .catch(error => {
                console.error('Error fetching data', error);
            });
    }

    const columnsCEX: any = [
        {
            title: 'Exchange',
            dataIndex: 'Exchange',
            key: 'Exchange',
            align: 'left',
            render: (_:any, it:any) => {
                return <div className={styles.home_card_item_r_exchange_wrap}>
                    {<img className={styles.home_card_item_r_logo} key={it.exchange} src={imgs[it.exchange.toLowerCase()]} alt=""/>}
                    <div className={styles.home_card_item_r_exchange}>{it.exchange}</div>
                </div>
            },
        },
        {
            title: 'Price(24H %)',
            width: 300,
            dataIndex: 'priceChangePercent',
            key: 'priceChangePercent',
            align: 'right',
            render: (_:any, item:any) => {
                return <span className={styles.cell200} > ${item.price > 1 ? Number(item.price).toFixed(2) : formatNumber(item.price.toString())}
                    <span className={item.priceChangePercent > 0 ? styles.up : styles.down}>({Number(item.priceChangePercent).toFixed(2)}%)</span>
              </span>
            },
        },
        {
            title: 'Volume(24H %)',
            width: 200,
            dataIndex: 'quoteVolume',
            key: 'quoteVolume',
            align: 'right',
            render: (_:any, item:any) => {
                return <span className={styles.cell200} >${getFormatVal(item.quoteVolume)}
                    {/* <span className={item.priceChangePercent > 0 ? styles.up : styles.down}>({Number(item.priceChangePercent).toFixed(2)}%)</span> */}
              </span>
            },
        },
        {
            title: '+2% Depth',
            key: 'depthUsdPositiveTwo',
            dataIndex: 'depthUsdPositiveTwo',
            align: 'right',
            render: (_: any, item: any) => {
                return <span className={styles.cell200}>$ {getFormatVal(item.depthUsdPositiveTwo)}</span>
            },
        },
        {
            title: '-2% Depth',
            key: 'depthUsdPositiveTwo',
            dataIndex: 'depthUsdPositiveTwo',
            align: 'right',
            render: (_: any, item: any) => {
                return <span className={styles.cell200}>$ {getFormatVal(item.depthUsdNegativeTwo)}</span>
            },
        },
        {
            title: 'Buy/Sell',
            key: 'Buy/Sell',
            dataIndex: 'Buy/Sell',
            align: 'right',
            render: (_: any, item: any) => {
                const buyOrdersPercent = +Number(+item.buyOrdersAmount / (+item.buyOrdersAmount + +item.sellOrdersAmount) * 100).toFixed(2);
                const sellOrdersPercent = +Number(100 - buyOrdersPercent).toFixed(2);
                return <div className={styles.percentWrap}>
                    <div className={styles.percentItem}>
                        <div className={styles.percent}>{buyOrdersPercent}%</div>
                        <div className={styles.percent}>{sellOrdersPercent}%</div>
                    </div>
                    <div className={styles.percentItem}>
                        <div className={styles.buyOrdersProgress} style={{width: buyOrdersPercent + '%'}}></div>
                        <div className={styles.sellOrdersProgress} style={{width: sellOrdersPercent + '%'}}></div>
                    </div>
                </div>
            },
        }
    ];

    const columnsDEX: any = [
        {
            title: 'Exchange',
            dataIndex: 'Exchange',
            key: 'Exchange',
            align: 'left',
            render: (_:any, it:any) => {
                return <div className={styles.home_card_item_r_exchange_wrap}>
                    {<img className={styles.home_card_item_r_logo} key={it.exchange} src={imgs[it.exchange.toLowerCase()]} alt=""/>}
                    <div className={styles.home_card_item_r_exchange}>{it.exchange}</div>
                </div>
            },
        },
        {
            title: 'Price(24H %)',
            dataIndex: 'priceChangePercent',
            key: 'priceChangePercent',
            align: 'right',
            render: (_: any, item: any) => {
                return <span className={styles.cell200}> ${item.price}
                    <span
                        className={item.priceChangePercent > 0 ? styles.up : styles.down}>({Number(item.priceChangePercent).toFixed(2)}%)</span>
              </span>
            },
        },
        {
            title: 'Volume(24H %)',
            dataIndex: 'quoteVolume',
            key: 'quoteVolume',
            align: 'right',
            render: (_: any, item: any) => {
                return <span className={styles.cell200}>${getFormatVal(item.quoteVolume)}
                    {/* <span className={item.priceChangePercent > 0 ? styles.up : styles.down}>({Number(item.priceChangePercent).toFixed(2)}%)</span> */}
              </span>
            },
        },
        {
            title: 'Liquidity',
            key: 'depthUsdPositiveTwo',
            dataIndex: 'depthUsdPositiveTwo',
            align: 'right',
            render: (_: any, item: any) => {
                return <span className={styles.cell200}>-</span>
            },
        },
        {
            title: '+100 Price Impact',
            key: 'depthUsdPositiveTwo',
            dataIndex: 'depthUsdPositiveTwo',
            align: 'right',
            render: (_: any, item: any) => {
                return <span className={styles.cell200}>-</span>
            },
        },
        {
            title: '-100 Price Impact',
            key: 'depthUsdPositiveTwo',
            dataIndex: 'depthUsdPositiveTwo',
            align: 'right',
            render: (_: any, item: any) => {
                return <span className={styles.cell200}>-</span>
            },
        },
        {
            title: 'Buy/Sell',
            key: 'Buy/Sell',
            dataIndex: 'Buy/Sell',
            align: 'right',
            render: (_: any, item: any) => {
                const buyOrdersPercent = +Number(+item.buyOrdersAmount / (+item.buyOrdersAmount + +item.sellOrdersAmount) * 100).toFixed(2);
                const sellOrdersPercent = +Number(100 - buyOrdersPercent).toFixed(2);
                return <div className={styles.percentWrap}>
                    <div className={styles.percentItem}>
                        <div className={styles.percent}>{buyOrdersPercent}%</div>
                        <div className={styles.percent}>{sellOrdersPercent}%</div>
                    </div>
                    <div className={styles.percentItem}>
                        <div className={styles.buyOrdersProgress} style={{width: buyOrdersPercent + '%'}}></div>
                        <div className={styles.sellOrdersProgress} style={{width: sellOrdersPercent + '%'}}></div>
                    </div>
                </div>
            },
        }
    ];

    function getFormatVal(val: number) {
        if(isNaN(val) || val === 0){
            return '--'
        }
        return Number(Math.floor(val)).toLocaleString('en');
    }

    const onChange = (e: string) => {
        setActiveTab(e as 'Overview'|'Unlocks'|'Market')
        if (e === "Overview") {
            Overview.current && Overview.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
            })
            return
        }
        if (e === "Unlocks") {
            Unlock.current && Unlock.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
            })
            return
        }
        Markets.current && Markets.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        })
    }
    const toBrowser = () => {
        window.location.href = 'https://dexbrowser.medium.com';
    }
    const toOfficial = () => {
        window.location.href = 'https://t.me/dexbrowserofficial';
    }
    const toTwitter = () => {
        window.location.href = 'https://twitter.com/dexbrowser';
    }
    const chartTimeHandle = (time: string) => {
        getKLinesRequest(exchanges[0].name, exchanges[0].pair, time);
    }


    const formatAmountPercent = (val: number) => {
        if(val === 0 || isNaN(val)) {
            return '--'
        }
        return (val/+(+progress.lockedAmount + +progress.unlockedAmount
            + +progress.untrackedAmount) * 100).toFixed(2) + '%'
    }
    const toDashboard = () => {
        navigator(`/dashboard`, {state: {active: 'dashboard'}})
    }
    return <>
        <Header/>
        <div className={styles.detail_wrap}>
            <div className={styles.detail_content}>
                <div className={styles.detail_head}>
                    <div className={styles.detail_head_left}>
                        <div className={styles.detail_head_info}>
                            <div className={styles.detail_head_icon}>
                                <Image preview={false} className={styles.detail_head_icon_img}
                                       src={coin.logo}></Image>
                            </div>
                            <div className={styles.detail_head_info_right}>
                                <div className={styles.detail_title}>
                                    {}
                                    <div className={styles.detail_head_name}>
                                        {location.state?.type === 'launchpad' ? coin.symbol : coin.name}
                                        ({location.state?.type === 'launchpad' ? coin.name : coin.symbol})
                                    </div>
                                    {/*<div className={styles.detail_head_tag}>Modular blockchain</div>*/}
                                </div>
                                <div className={styles.detail_price_wrap}>
                                    <div className={styles.detail_price}>${(+coin.price).toFixed(2)}</div>
                                    <div className={classnames(styles.detail_price_precent, +coin.priceChangePercent > 0 ? styles.up : styles.down)}>{+coin.priceChangePercent > 0 && '+'}{(+coin.priceChangePercent).toFixed(2)}%
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.detail_tips} ref={Overview}>{coin.description}</div>
                    </div>
                    <div className={styles.detail_head_right}>
                        <div className={styles.detail_links_wrap}>
                            <div className={styles.detail_links}>
                                <img src={website} className={styles.links_icon} alt="" onClick={toBrowser}/>
                            </div>
                            <div className={styles.detail_links}>
                                <img src={telegram} className={styles.links_icon} alt="" onClick={toOfficial}/>
                            </div>
                            <div className={styles.detail_links}>
                                <img src={twitter} className={styles.links_icon} alt="" onClick={toTwitter}/>
                            </div>
                        </div>
                        <div className={styles.detail_head_wrap_btn}>
                            <div className={styles.add_to_watch_list}>Add to watchlist</div>
                            <div className={styles.track_in_dashboard} onClick={toDashboard}>Track in dashboard</div>
                        </div>
                    </div>
                </div>
                <div className={styles.detail_line}></div>
                <div className={styles.navs_wrap}>
                    <Tabs activeKey={activeTab} defaultActiveKey={"Overview"} items={items} indicatorSize={60} onChange={onChange} />
                </div>
                <div className={styles.nav_items}>
                    <div className={styles.nav_item}>
                        <div className={styles.nav_item_child}>
                            <div className={styles.nav_item_child_left}>Total supply</div>
                            <div className={styles.nav_item_child_right}>{getFormatVal(+supply.totalSupply)} 
                            {location.state?.type === 'launchpad' ? coin.name : coin.symbol}</div>
                        </div>
                        <div className={styles.nav_item_child}>
                            <div className={styles.nav_item_child_left}>Max supply</div>
                            <div className={styles.nav_item_child_right}>{supply.maxSupply === '♾️' ? '♾️' : getFormatVal(+supply.maxSupply)} 
                            {location.state?.type === 'launchpad' ? coin.name : coin.symbol}</div>
                        </div>
                        <div className={styles.nav_item_child}>
                            <div className={styles.nav_item_child_left}>Circulating supply</div>
                            <div className={styles.nav_item_child_right}>{getFormatVal(+supply.circulatingSupply)} 
                            {location.state?.type === 'launchpad' ? coin.name : coin.symbol}
                            </div>
                        </div>
                        <div className={styles.nav_item_child}>
                            <Progress percent={+(Number(supply.circulatingSupply)/Number(supply.totalSupply) * 100).toFixed(2)} strokeColor="#23C448"/>
                        </div>
                    </div>
                    <div className={styles.nav_item}>
                        <div className={styles.nav_item_child}>
                            <div className={styles.nav_item_child_left}>Market cap</div>
                            <div className={styles.nav_item_child_right}>
                                {
                                    (isNaN(+supply.marketCapChangePercentage24H) || supply.marketCapChangePercentage24H === 0) 
                                    && (isNaN(+supply.marketCap) || +supply.marketCap === 0) ? '--' :
                                        <>${getFormatVal(+supply.marketCap)} <span
                                            className={classnames([styles.nav_item_child_right_color, supply.marketCapChangePercentage24H > 0 ? styles.up : styles.down])}>
                                            ({supply.marketCapChangePercentage24H > 0 && '+'}
                                            {!supply.marketCapChangePercentage24H ? '--' : (+supply.marketCapChangePercentage24H).toFixed(2)}%)</span>
                                        </>
                                }
                            </div>
                        </div>
                        <div className={styles.nav_item_child}>
                            <div className={styles.nav_item_child_left}>Fully Diluted Value</div>
                            <div className={styles.nav_item_child_right}>
                                {(!isNaN(+supply.fullyDilutedValuation) && +supply.fullyDilutedValuation !== 0) && '$'}{getFormatVal(+supply.fullyDilutedValuation)}
                            </div>
                        </div>
                        <div className={styles.nav_item_child}>
                            <div className={styles.nav_item_child_left}>Volume(24h)</div>
                            <div className={styles.nav_item_child_right}>{supply.totalVolume24H && '$'}{getFormatVal(+supply.totalVolume24H)}</div>
                        </div>
                        <div className={styles.nav_item_child}>
                            <div className={styles.nav_item_child_left}>Volume/Market cap(24h)</div>
                            <div className={styles.nav_item_child_right}>{!supply.totalVolume24H ? '--' : +supply.marketCap === 0 ? '♾️' :
                                (Number(supply.totalVolume24H)/Number(supply.marketCap)).toFixed(2) + '%'}</div>
                        </div>
                    </div>
                    <div className={styles.nav_item}>
                        <div className={styles.nav_item_child}>
                            <div className={styles.nav_item_child_left}>Token Holders</div>
                            <div className={styles.nav_item_child_right}>--</div>
                        </div>
                        <div className={styles.nav_item_child}>
                            <div className={styles.nav_item_child_left}>Transfers(24H)</div>
                            <div className={styles.nav_item_child_right}>--</div>
                        </div>
                        <div className={styles.nav_item_child}>
                            <div className={styles.nav_item_child_left}>TransferVolume(24H)</div>
                            <div className={styles.nav_item_child_right}>--</div>
                        </div>
                        <div className={styles.nav_item_child}>
                            <div className={styles.nav_item_child_left}>Liquidity</div>
                            <div className={styles.nav_item_child_right}>--</div>
                        </div>
                    </div>
                </div>
                <div className={styles.detail_dashboard}>
                    <div className={styles.detail_dashboard_price}>
                        <div className={styles.detail_dashboard_price_title}>
                            <div className={styles.hot}>🔥</div>
                            <p>Price</p>
                        </div>
                        <div className={styles.detail_dashboard_price_items}>
                            <div className={styles.detail_dashboard_price_item}>
                                <div className={styles.detail_dashboard_price_item_top}>
                                    <p>5m</p>
                                    {/*<img src={latestLines[0] && latestLines[0].priceChangePercent1H > 0 ? greenIcon : redIcon} className={styles.redIcon} alt=""/>*/}
                                </div>
                                <div className={classnames([styles.detail_dashboard_price_item_bot, styles.detail_dashboard_price_item_bot_gray])}>--</div>
                            </div>
                            <div className={styles.detail_dashboard_price_item}>
                                <div className={styles.detail_dashboard_price_item_top}>
                                    <p>1h</p>
                                    <img src={latestLines[0] && latestLines[0].priceChangePercent1H > 0 ? greenIcon : redIcon} className={styles.redIcon} alt=""/>
                                </div>
                                <div className={classnames([styles.detail_dashboard_price_item_bot,
                                    latestLines[0] && latestLines[0].priceChangePercent1H > 0 && styles.detail_dashboard_price_item_bot_green])}>
                                    {latestLines[0] && latestLines[0].priceChangePercent1H > 0 && '+'}{latestLines[0] && latestLines[0].priceChangePercent1H}%
                                </div>
                            </div>
                            <div className={styles.detail_dashboard_price_item}>
                                <div className={styles.detail_dashboard_price_item_top}>
                                    <p>24h</p>
                                    <img src={latestLines[0] && latestLines[0].priceChangePercent24H > 0 ? greenIcon : redIcon} className={styles.redIcon} alt=""/>
                                </div>
                                <div className={classnames([styles.detail_dashboard_price_item_bot,
                                    latestLines[0] && latestLines[0].priceChangePercent24H > 0 && styles.detail_dashboard_price_item_bot_green])}>
                                    {latestLines[0] && latestLines[0].priceChangePercent24H > 0 && '+'}{latestLines[0] && latestLines[0].priceChangePercent24H}%
                                </div>
                            </div>
                            <div className={styles.detail_dashboard_price_item}>
                                <div className={styles.detail_dashboard_price_item_top}>
                                    <p>7d</p>
                                    <img src={latestLines[0] && latestLines[0].priceChangePercent7D > 0 ? greenIcon : redIcon} className={styles.redIcon} alt=""/>
                                </div>
                                <div className={classnames([styles.detail_dashboard_price_item_bot,
                                    latestLines[0] && latestLines[0].priceChangePercent7D > 0 && styles.detail_dashboard_price_item_bot_green])}>
                                    {latestLines[0] && latestLines[0].priceChangePercent7D > 0 && '+'}{latestLines[0] && latestLines[0].priceChangePercent7D}%
                                </div>
                            </div>
                        </div>
                        <div className={styles.detail_dashboard_price_time}>
                            <div
                                className={classnames([styles.detail_dashboard_price_time_item, styles.detail_dashboard_price_time_item_active])}>5m
                            </div>
                            <div className={styles.detail_dashboard_price_time_item}>15m</div>
                            <div className={styles.detail_dashboard_price_time_item}>30m</div>
                            <div className={styles.detail_dashboard_price_time_item}>1h</div>
                            <div className={styles.detail_dashboard_price_time_item}>6h</div>
                            <div className={styles.detail_dashboard_price_time_item}>24h</div>
                        </div>
                        <div className={styles.detail_dashboard_price_data}>
                            <div className={styles.detail_dashboard_price_data_top}>
                                <div className={styles.detail_dashboard_price_data_item}>
                                    <div>TXNS</div>
                                    <div className={styles.detail_dashboard_price_data_item_val}>--</div>
                                </div>
                                <div className={styles.detail_dashboard_price_data_item}>
                                    <div>BUYS</div>
                                    <div className={styles.detail_dashboard_price_data_item_val}>--</div>
                                </div>
                            </div>
                            <div className={styles.detail_dashboard_price_data_top}>
                                <div className={styles.detail_dashboard_price_data_item}>
                                    <div>SELLS</div>
                                    <div className={styles.detail_dashboard_price_data_item_val}>--</div>
                                </div>
                                <div className={styles.detail_dashboard_price_data_item} ref={Unlock}>
                                    <div>VOLUME</div>
                                    <div className={styles.detail_dashboard_price_data_item_val}>--</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.detail_dashboard_data}>
                        <LineChart priceData={priceData} chartTimeHandle={chartTimeHandle}/>
                    </div>
                </div>
                <div className={styles.detail_events}>
                    <div className={styles.detail_event_left}>
                        <div className={styles.detail_event_title}>Unlock</div>
                        <div className={styles.detail_event_card}>
                            <PieChart progress={progress} total={+progress.lockedAmount + +progress.unlockedAmount + +progress.untrackedAmount}/>
                            <div className={styles.detail_event_card_li}>
                                <div className={styles.detail_event_card_li_left}>
                                    <div className={styles.detail_event_card_li_yuan}></div>
                                    <div className={styles.detail_event_card_li_text}>Total Locked</div>
                                </div>
                                <div className={styles.detail_event_card_li_right}>
                                    <div className={styles.detail_event_card_li_val}>{formatAmount(+progress.lockedAmount)}</div>
                                    <div className={styles.detail_event_card_li_tag}>{location.state?.type === 'launchpad' ? coin.name : coin.symbol}</div>
                                    <div className={styles.detail_event_card_li_precent}>
                                        {formatAmountPercent(+progress.lockedAmount)}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.detail_event_line}></div>
                            <div className={styles.detail_event_card_li}>
                                <div className={styles.detail_event_card_li_left}>
                                    <div
                                        className={classnames([styles.detail_event_card_li_yuan, styles.detail_event_card_li_yuan_green])}></div>
                                    <div className={styles.detail_event_card_li_text}>Unlocked</div>
                                </div>
                                <div className={styles.detail_event_card_li_right}>
                                    <div className={styles.detail_event_card_li_val}>{formatAmount(+progress.unlockedAmount)}</div>
                                    <div className={styles.detail_event_card_li_tag}>{location.state?.type === 'launchpad' ? coin.name : coin.symbol}</div>
                                    <div className={styles.detail_event_card_li_precent}>
                                        {formatAmountPercent(+progress.unlockedAmount)}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.detail_event_line}></div>
                            <div className={styles.detail_event_card_li}>
                                <div className={styles.detail_event_card_li_left}>
                                    <div
                                        className={classnames([styles.detail_event_card_li_yuan, styles.detail_event_card_li_yuan_disable])}></div>
                                    <div className={styles.detail_event_card_li_text}>Untracked</div>
                                </div>
                                <div className={styles.detail_event_card_li_right}>
                                    <div className={styles.detail_event_card_li_val}>{formatAmount(+progress.untrackedAmount)}</div>
                                    <div className={styles.detail_event_card_li_tag}>{location.state?.type === 'launchpad' ? coin.name : coin.symbol}</div>
                                    <div className={styles.detail_event_card_li_precent}>
                                        {formatAmountPercent(+progress.untrackedAmount)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.detail_event_center}>
                        <div className={styles.detail_event_center_title}>Unlock Events</div>
                        <div className={styles.unlockEventsWrap}>
                            <Slider {...settings}>
                                {event && event.map(item => (
                                    <div className={styles.unlockEventsItem} key={item.unlockTime}>
                                        <UpComing locks={item} price={supply.currentPrice} marketCap={supply.marketCap} cirSupply={supply.circulatingSupply} page={'home'} title={item.type === 'previousUnlocks' ? 'Historical': 'Upcoming'}></UpComing>
                                    </div>
                                ))}
                            </Slider>
                            {event.length > 3 && <div className={styles.otherDots}>
                                <div className={styles.dot}></div>
                                <div className={styles.dot}></div>
                            </div>}
                            {event.length === 0 && <div className={styles.noUnlock}>No Unlock Events</div>}
                        </div>
                    </div>
                    {/*<div className={styles.detail_event_right}>*/}
                    {/*    <div className={classnames([styles.detail_event_card, styles.detail_event_upgrade])}>*/}
                    {/*        <img src={upgradeIcon} className={styles.detail_event_upgrade_icon} alt=""/>*/}
                    {/*        <div className={styles.detail_event_upgrade_title}>Discover more past & upcoming unlock*/}
                    {/*            events.*/}
                    {/*        </div>*/}
                    {/*        <div className={styles.detail_event_upgrade_title2}>Unlock the past and embrace the future*/}
                    {/*            with 2past and 3 upcoming events*/}
                    {/*        </div>*/}
                    {/*        <div className={styles.detail_event_upgrade_btn}>Upgrade</div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                <div className={styles.detail_table_area} ref={Markets}>
                    <div className={styles.detail_table_head}>
                        <div className={styles.detail_table_title}>Markets</div>
                        <div className={styles.detail_table_menu}>
                            <div onClick={() => setActiveTableNav('cex')}
                                className={classnames([styles.detail_table_menu_item, activeTableNav === 'cex' && styles.detail_table_menu_active])}>CEX
                            </div>
                            <div onClick={() => setActiveTableNav('dex')} className={classnames([styles.detail_table_menu_item,
                                activeTableNav === 'dex' && styles.detail_table_menu_active])}>DEX</div>
                        </div>

                    </div>
                    <div className={styles.detail_table}>
                        <Table columns={activeTableNav === 'cex' ? columnsCEX : columnsDEX} dataSource={latestTickers}
                               pagination={{position: ["none", "bottomCenter"]}}/>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </>
}

export default Detail