import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import {Image, Select} from 'antd';
import {postRequest} from "../../helper/request";
import styles from "../index.module.scss";
export interface Coin{
  slug: string,
  logo: string,
  name: string,
  symbol: string,
  exchanges?: any
}
interface Props{
  handleChange: (value: Coin) => void
}
const SearchInput = forwardRef((props: Props, ref) => {
  const [data, setData] = useState<Coin[]>([]);
  const [value, setValue] = useState<string>();
  useImperativeHandle(ref, () => ({
    setValue,
  }));
  useEffect(() => {
    postRequest('/tokens?withExchanges=true')
        .then(res => {
          const oldData = res[0] as unknown as Coin[]
          const data = oldData.map(item => ({slug: item.slug, logo: item.logo, name: item.name, symbol: item.symbol, exchanges: item.exchanges}));
          setData(data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
  }, []);
const handleSelect = (newValue: string) => {  
    const selectData = data.filter(item => item.name === newValue);
    if(selectData.length === 0) {
        return
    }
    setValue(newValue);
    props.handleChange(selectData[0]);
}
  return (
      <Select
          showSearch
          value={value}
          placeholder={'search'}
          style={{width: 200}}
          bordered={false}
          defaultActiveFirstOption={false}
          suffixIcon={null}
          onSelect={handleSelect}
          notFoundContent={null}
          options={(data || []).map((d) => ({
            value: d.name,
            label: (
                <div className={styles.optionWrap} key={d.name}>
                   <Image preview={false} className={styles.selectImg} src={d.logo}></Image>
                    <div className={styles.searchText}>{d.name}</div>
                </div>
            ),
          }))}
      />
  );
});

export default SearchInput;
