import styles from "./index.module.scss";
import {Modal, Image, message} from "antd";
import {forwardRef, useImperativeHandle, useState, useRef} from "react";
import SearchInput, {Coin} from "./SearchInput/index";
import search from './image/search.png';
import classnames from "classnames";

interface Props {
  handleOk: (data: Coin[]) => void
}
const BotModal = forwardRef((props: Props, ref) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [coin, setCoin] = useState<Coin>({} as Coin);
  const searchInput = useRef<any>(null);
  const showModal = () => {
    setIsModalOpen(true);
  };
  useImperativeHandle(ref, () => ({
    showModal,
  }));


  const onCancel = () => {
    searchInput.current && searchInput.current.setValue();
    setIsModalOpen(false);
  }
  const handleOk = () => {
    if(!coin.name) {
      
      // message.error('select a token');
      return
    }
    let data = [];
    try {
      let strData = localStorage.getItem('tokenData') || '';
      if(strData) {
        data = JSON.parse(strData);
      }
      if(data.find((item: Coin) => item.name === coin.name)) {
        message.config({
          top: 100,
          duration: 2,
          maxCount: 3,
          getContainer: () => document.body, // 设置 message 全局配置的容器
        });
        message.error('Error');
        return
      }
      data.push({
        name: coin.name,
        symbol: coin.symbol,
        exchanges: coin.exchanges
      })
      localStorage.setItem('tokenData', JSON.stringify(data));
    } catch (error) {
      console.log(error);
    }
    props.handleOk(data);
    onCancel();
  }
  const handleChange = (coin: Coin) => {
    setCoin(coin);
  }
  return (
    <Modal
      open={isModalOpen}
      centered
      getContainer={() => document.body}
      onCancel={onCancel}
      closable={true}
      maskClosable={false}
      zIndex={99}
      footer={null}>
      <div className={styles.bot_delete_wrap}>
        <p className={styles.delete_tips}>Import Token</p>
        <div className={styles.delete_card_wrap}>
          <div className={styles.delete_card}>
            <div className={styles.searchInput}>
              <Image preview={false} className={styles.searchIcon} src={search}></Image>
              <SearchInput ref={searchInput} handleChange={handleChange} />
            </div>
          </div>
        </div>
        <div className={styles.delete_btn_wrap}>
          <div className={classnames([styles.delete_confirm, !coin.name && styles.delete_confirm_no_select])} onClick={handleOk}>Confirm</div>
        </div>
      </div>
    </Modal>
  )
})

export default BotModal