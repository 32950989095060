export function formatNumber(value: string) {
    if (!value.includes('.') || value.split('.')[1].length <= 4) {
        return value;
    }
    const decimalPart = value.split('.')[1];
    for (let i = 0; i < decimalPart.length; i++) {
        if(Number(decimalPart[i]) > 0){
            const strNum = Number(value).toFixed(i + 4);
            return strNum.replace(/(\.\d*?[1-9])0+$/g, '$1');
        }
    }
    return value;
}

export const formatAmount = (val: number) => {
    if(val === 0 || isNaN(val)) {
        return '--'
    }
    if(val > 1000000000) {
        return (val/1000000000).toFixed(2) + 'b'
    }
    if(val > 1000000) {
        return (val/1000000).toFixed(2) + 'm'
    }
    if(val > 1000) {
        return (val/1000).toFixed(2) + 'k'
    }
    return val.toFixed(2)
}
