import styles from './index.module.scss';
import React from "react";
import {Locks} from "./ImgFormCarousel";
import classnames from "classnames";

const Upcoming = (props: {locks: Locks[], page?: string, width?: number, height?: number}) => {
    return (
        <div className={styles.upcomingBlockWrap} style={{width: props.width + 'px', height: props.height + 'px'}}>
            <div className={classnames(styles.upcomingTopWrap, props.page === 'home' && styles.homeUpcomingTopWrap)}>
                <div className={classnames(styles.upcomingTopTitle, props.page === 'home' && styles.homeUpcomingTopTitle)}>Upcoming</div>
                <div className={classnames(styles.upcomingTopTime, props.page === 'home' && styles.homeUpcomingTopTime)}>2023-11-05 17:00:00</div>
            </div>
            <div className={classnames(styles.upcomingInfoWrap, props.page === 'home' && styles.homeUpcomingInfoWrap)}>
                <div className={classnames(styles.upcomingIcon, props.page === 'home' && styles.homeUpcomingIcon)}>
                    {/*<Image preview={false} className={classnames([styles.leftArrowImg, styles.rightArrowImg])} src={rightArrow}></Image>*/}
                </div>
                <div className={classnames(styles.upcomingContentWrap, props.page === 'home' && styles.homeUpcomingContentWrap)}>
                    <div className={classnames(styles.upcomingContentTitle, props.page === 'home' && styles.homeUpcomingContentTitle)}>15.60m TIA(4.23%)</div>
                    <div className={classnames(styles.upcomingContentMoney, props.page === 'home' && styles.homeUpcomingContentMoney)}>
                        ${props.locks[1] && props.locks[1].linearUnlockAmountPerDay || '$23.25m'}m
                    </div>
                </div>
            </div>
            <div className={classnames(styles.allocationTitleWrap, props.page === 'home' && styles.homeAllocationTitleWrap)}>
                <div className={styles.allocationLeftWrap}>
                    <div className={classnames(styles.allocationLeftIcon, props.page === 'home' && styles.homeAllocationLeftIcon)}>
                        {/*<Image preview={false} className={classnames([styles.leftArrowImg, styles.rightArrowImg])} src={rightArrow}></Image>*/}
                    </div>
                    <div className={classnames(styles.allocationLeftTitle, props.page === 'home' && styles.homeAllocationLeftTitle)}>Allocation</div>
                </div>
                <div className={classnames(styles.allocationNumber, props.page === 'home' && styles.homeAllocationNumber)}>4</div>
            </div>
            <div className={classnames(styles.allocationList, props.page === 'home' && styles.homeAllocationList)}>
                {
                    // props.locks[1].allocations.
                    new Array(3).fill(1).map((item, index) => (
                        <div className={classnames(styles.allocationItem, props.page === 'home' && styles.homeAllocationItem)} key={index}>
                            <div className={classnames(styles.allocationItemType, props.page === 'home' && styles.homeAllocationItemType)}></div>
                            <div className={styles.allocationTopWrap}>
                                <div className={classnames(styles.allocationTopTitle, props.page === 'home' && styles.homeAllocationTopTitle)}>Charity</div>
                                <div className={classnames(styles.allocationTopMoney, props.page === 'home' && styles.homeAllocationTopMoney)}>$413.89K</div>
                            </div>
                            <div className={classnames(styles.allocationMiddleWrap, props.page === 'home' && styles.homeAllocationMiddleWrap)}>
                                <div className={classnames(styles.allocationMiddleWrap, props.page === 'home' && styles.homeAllocationMiddleWrap)}>
                                    <div className={classnames(styles.allocationMiddleNumber, props.page === 'home' && styles.homeAllocationMiddleNumber)}>2.22m </div>
                                    <span className={classnames(styles.allocationMiddleTips, props.page === 'home' && styles.homeAllocationMiddleTips)}>APE</span>
                                </div>
                                <div className={classnames(styles.allocationMiddleMoney, props.page === 'home' && styles.homeAllocationMiddleMoney)}>0.23% of M.Cap</div>
                            </div>
                            <div className={classnames(styles.allocationBottomWrap, props.page === 'home' && styles.homeAllocationBottomWrap)}>0.60% Cir. Supply</div>
                        </div>))
                }

            </div>
        </div>
    );
};

export default Upcoming;