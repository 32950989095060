import styles from "./index.module.scss"
import logo from './image/logo.png'
import telegram from "./image/telegram.png"
import twitter from "./image/twitter.png"
import  wallet from "./image/wallet.png"
import  icon1 from "./image/icon1.png"
import  icon2 from "./image/icon2.png"
import botIcon from "./image/bot.png"
import deleteIcon from "./image/delect.png"
import  rightBlack from "./image/right_black.png"
import {message, Modal, Popover, Switch, Tabs} from 'antd';
import type { TabsProps } from 'antd';
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState, useImperativeHandle, forwardRef} from "react";
import Web3 from "web3";
import {getRequest, postRequest} from "../../helper/request";
import { getEIP712LoginData, signEIP712LoginData,  } from '@volare.finance/utils.js';
import { ExternalProvider, Web3Provider, } from "@ethersproject/providers";
// @ts-ignore
import detectEthereumProvider from "@metamask/detect-provider";
import { Wallet } from 'ethers';
import BotModal from "./BotModal";
interface Info {
    name: string;
    image: string;
    owner: string;
    telegramID: string;
}
interface Rules {
    description: string;
    name: string;
    ruleId: string;
    rules: [];
}
interface Nonce {
    nonce: string;
    result: string;
}
interface Subscribed {
    ctime: number;
    ruleId: string;
    status: number;
}
interface props{
    
}
const Header = forwardRef((props: props, ref) => {
    const navigate = useNavigate()
    const location = useLocation();
    const [text, setText] = useState<string>("")
    const [token, setToken] = useState<string>("")
    const [info, setInfo] = useState<Info>({} as Info)
    const [isShowModal, setIsShowModal] = useState<boolean>(false)
    const botModal = useRef<any>(null)
    const [showInfo, setShowInfo] = useState<number>(-1)
    const [visible, setVisible] = useState(false)
    const [rules, setRules] = useState<Rules[]>([])
    const [ruleIds, setRuleIds] = useState<string[]>([])
    const [allSwitch, setAllSwitch] = useState<boolean>(false)
    const [telegramID, setTelegramID] = useState<number>(0)
    useImperativeHandle(ref, () => ({
        connectWallet,
      }));
    useEffect(() => {
        const key = window.location.hash.substring(2) || 'home';
        navigate(`/${key}`, {state: {active: key}});
        document.body.addEventListener('click', () => {
            setIsShowModal(false);
        })
        getUserInfo();
        updatedAccount();
    }, [])

    const items: TabsProps['items'] = [
        {
            key: 'home',
            label: 'Home'
        },
        {
            key: 'dashboard',
            label: 'Dashboard'
        },
        // {
        //     key: 'bots',
        //     label: 'Bots'
        // },
    ];
    const onChange = (key: string) => {
        navigate(`/${key}`, {state: {active: key}})
    };

    const getActive = () => {
        const pathname = location.pathname
        if (pathname.indexOf("home") < 0 && pathname.indexOf("dashboard") < 0 && pathname.indexOf("bots") < 0) {
            return ''
        }
        if (!location.state) {
            return "home"
        }
        return location.state.active
    }

    const connectWallet = () => {
        if ((window as any).ethereum) {
            (window as any).ethereum.enable()
                .then((res: any) => {
                    setToken(res);
                    getNonceRequest(res);
                })
                .catch((error: any) => {
                    if (error.message.includes('Already processing eth_requestAccounts')) {
                        // 捕获到错误，表示已经有请求在处理中
                        message.error('MetaMask is already processing, Please open MetaMask extension to connect.');
                    }
                });
        } else {
            message.error('MetaMask is not processing, Please download MetaMask extension to connect.');

        }
    }
    const getUpdatedAccountNonceRequest = (token: string) => {
        if(Array.isArray(token)) {
            token = token[0];
        }
        getRequest(`/user/${token}/nonce`)
            .then(async (res: any) => {
                const web3 = new Web3((window as any).ethereum);
                const chainId = await web3.eth.getChainId();
                const serializedChainId = chainId.toString(); // 将 BigInt 转换为字符串
                const loginData = getEIP712LoginData({ chainId: +serializedChainId, name: 'Login' },
                    { address: token, nonce:  res[0].nonce});
                const getProvider = async () => {
                    return new Web3Provider((await detectEthereumProvider()) as ExternalProvider);
                };
                const getSigner = async () => {
                    return (await getProvider()).getSigner();
                };
                const signer = await getSigner();
                loginData.message.address = token;
                const [loginSig, loginSigBytes] = await signEIP712LoginData(signer as unknown as Wallet, loginData);
                postRequest(`/user/${token}/signin`, {data: loginData, signature: loginSig})
                    .then((res: any) => {
                        localStorage.setItem('jwt', res[0].accessToken);
                        getUserInfo();
                        window.location.reload();
                    })
                    .catch((error:any) => {
                        console.error('Error fetching data:', error);
                    });
            })
            .catch((error:any) => {
                console.error('Error fetching data:', error);
            });
    };
    const updatedAccount = () => {
        try {
            // 监听账户变化
            if((window as any).ethereum) {
                (window as any).ethereum.on('accountsChanged', function (accounts: string[]) {
                    if(accounts.length === 0) {
                        quitLogin();
                        return
                    }
                    const currentAccount = localStorage.getItem('account');
                    const updatedAccount = accounts[0];
                    if (updatedAccount !== currentAccount) {
                        localStorage.setItem('account', updatedAccount);
                        getUpdatedAccountNonceRequest(updatedAccount);
                    }
                });
            }
        } catch (error) {
            
        }
    }
    const getNonceRequest = (token: string) => {
        if(Array.isArray(token)) {
            token = token[0];
        }
        getRequest(`/user/${token}/nonce`)
            .then((res: any) => {
                getSignRequest(token, res[0].nonce);
            })
            .catch((error:any) => {
                console.error('Error fetching data:', error);
            });
    };
    async function getSignRequest(token: string, nonce: number){
        const web3 = new Web3((window as any).ethereum);
        const chainId = await web3.eth.getChainId();
        const serializedChainId = chainId.toString(); // 将 BigInt 转换为字符串
        const loginData = getEIP712LoginData({ chainId: +serializedChainId, name: 'Login' },
            { address: token, nonce:  nonce});
        web3.eth.getAccounts()
            .then(async accounts => {
                // 检查是否有账户
                if (accounts.length > 0) {
                    const account = accounts[0];
                    localStorage.setItem('account', account);
                    const getProvider = async () => {
                        return new Web3Provider((await detectEthereumProvider()) as ExternalProvider);
                    };
                    const getSigner = async () => {
                        return (await getProvider()).getSigner();
                    };
                    const signer = await getSigner();
                    loginData.message.address = account;
                    const [loginSig, loginSigBytes] = await signEIP712LoginData(signer as unknown as Wallet, loginData);
                    postRequest(`/user/${token}/signin`, {data: loginData, signature: loginSig})
                        .then((res: any) => {
                            localStorage.setItem('jwt', res[0].accessToken);
                            getUserInfo();
                            if(location.state.active !== 'home') {
                                window.location.reload();
                            }
                        })
                        .catch((error:any) => {
                            console.error('Error fetching data:', error);
                        });
                }
            })
            .catch(error => {
                console.error("Error fetching accounts:", error);
            });
    }
    const getUserInfo = () => {
        getRequest(`/user/info`).then((res: any) => {
            const data = res[0] as unknown as Info;
            localStorage.setItem('owner', data && data.owner);
            setShowInfo(data && data.owner ? 1 : 2);
            setTelegramID(Number(data && data.telegramID));
            setInfo(data);
        })
        .catch((error:any) => {
            console.error('Error fetching data:', error);
        });
    }
    const toTg = () => {
        window.location.href = 'https://t.me/dexbrowserofficial';
    }
    const toTwitter = () => {
        window.location.href = 'https://twitter.com/dexbrowser';
    }
    const openInfo = (e: any) => {
        e.stopPropagation();
        setIsShowModal(!isShowModal);
    }
    const toProfile = () => {
        navigate(`/Profile`, {state: {active: 'profile'}})
    }
    const quitLogin = () => {
        setIsShowModal(false);
        setShowInfo(2);
        localStorage.removeItem('jwt');
        localStorage.removeItem('boardsIndex');
        localStorage.removeItem('uid');
        if(location.state.active === 'profile') {
            navigate(`/home`, {state: {active: 'home'}})
            return
        }
        if(location.state.active !== 'home') {
            window.location.reload();
        }
    }

    const onChangeAllSwitch = (change:boolean) => {
        setAllSwitch(!allSwitch);
        const params = rules.map(item => item.name);
        if(!change) {
            setRuleIds([]);
            getUnSubscribe(params);
        }
    }
    const onChangeSwitch = (params: string[], change:boolean) => {
        let result: string[] = ruleIds;
        if(change) {
            result.push(params[0]);
            getSubscribe(params);
        } else {
            result = result.filter(it => it !== params[0]);
            getUnSubscribe(params);
        }
        if(ruleIds.length > 0) {
            setAllSwitch(true);
        }
        setRuleIds([...result]);
    }
    const getSubscribe = (params: string[]) => {
        postRequest(`/rules/subscribe`, params)
            .then((res: any) => {
                // getSubscribed();
            })
            .catch((error:any) => {
                console.error('Error fetching data:', error);
            });
    }
    const getUnSubscribe = (params: string[]) => {
        postRequest(`/rules/unsubscribe`, params)
            .then((res: any) => {
                // getSubscribed();
            })
            .catch((error:any) => {
                console.error('Error fetching data:', error);
            });
    }
    const handleDelete = (type: string) => {
        let title = ""
        let value = ""
        if (type === "tokenTrack") {
            title = "Token Tracking"
            value = "Displays the latest market data for a coin in real-time"
        }
        botModal.current && botModal.current.showModal(title, value)
    }
    const disattachHandle = () => {
        postRequest(`/telegram/disattach`)
            .then((res: any) => {
                setVisible(false);
                postRequest(`/user/update`, {telegramID: 0}).then((res: any) => {});
                localStorage.setItem('telegramID', '0');
                setTelegramID(0);
            })
            .catch((error:any) => {
                console.error('Error fetching data:', error);
            });
    }
    const toMore = () => {
        setVisible(false);
        navigate(`/dashboard`, {state: {active: 'dashboard'}});
    }
    const content = (
      <div className={styles.bot_content}>
          <p className={styles.bot_tips}>By linking the Telegram bot you can subscribe to data on the Dexbro dashboard. </p>
          {/* <div className={styles.bot_all_switch}>
              <p style={{flex: 1}}>Configured information</p>
              <Switch checked={allSwitch} onChange={onChangeAllSwitch} />
          </div> */}
          {rules && rules.map(item => (<div className={styles.bot_switch} key={item.ruleId}>
              <div className={styles.bot_switch_top}>
                  <div>{item.name}</div>
                  <div className={styles.bot_switch_top_right}>
                      {/*<img src={deleteIcon} className={styles.bot_delete} alt=""*/}
                      {/*     onClick={() => handleDelete('tokenTrack')}/>*/}
                      <Switch disabled checked={ruleIds.includes(item.ruleId)} onChange={(e: boolean) => onChangeSwitch([item.ruleId], e)}/>
                  </div>
              </div>
              <div className={styles.bot_switch_bot}>{item.description}</div>
          </div>))}
          <div className={styles.bot_btn_wrap}>
              <div className={styles.disassociate} onClick={disattachHandle}>Disassociate</div>
              <div className={styles.subscribe} onClick={toMore}>Subscribe more</div>
          </div>
      </div>
    );

    const titleText = (
        <div className={styles.popover_title}>
            <p>Telegram Bot</p>
            <img src={botIcon} className={styles.bot_icon} alt=""/>
        </div>
    );
    const toHome = () => {
        navigate(`/home`, {state: {active: 'home'}})
    }
    const handleVisibleChange = () => {
        const telegramID = localStorage.getItem('telegramID');
        if(showInfo === 1) {
            if(telegramID && Number(telegramID) > 0) {
                if(!visible) {
                    getRules();
                    getSubscribed();
                }
                setVisible(!visible);
            } else {
                let number = 4;
                const timer = setInterval(() => {
                    number--;
                    const telegramID = localStorage.getItem('telegramID');
                    // number <= 0 || 
                    if(Number(telegramID) > 0) {
                        clearInterval(timer);
                    }
                    setTelegramID(Number(telegramID));
                    getUserInfoBot('timer');
                }, 2000);
                getUserInfoBot();
            }
        } else {
            connectWallet();
        }
    }
    const getUserInfoBot = (type?: string) => {
        getRequest(`/user/info`).then((res: any) => {
            const data = res[0] as unknown as Info;
            if(data.telegramID){
                localStorage.setItem('telegramID', data && data.telegramID);
                if(type === 'timer') return;
                if(!visible) {
                    getRules();
                }
                setVisible(!visible);
            } else {
                getNonce();
            }
        })
            .catch((error:any) => {
                console.error('Error fetching data:', error);
            });
    }
    const getNonce = () => {
        getRequest(`/telegram/nonce`).then((res: any) => {
            const account = localStorage.getItem('account');
            const data = res[0] as unknown as Nonce;
            window.open(`https://t.me/Dexbrowser_bot?start=a_${account && account.toLowerCase()}_${data.nonce}`)
        })
            .catch((error:any) => {
                console.error('Error fetching data:', error);
            });
    }
    const getRules = () => {
        postRequest(`/rules`).then((res: any) => {
            const data = res[0] as unknown as Rules[];
            setRules(data);
        })
            .catch((error:any) => {
                console.error('Error fetching data:', error);
            });
    }
    const getSubscribed = () => {
        postRequest(`/rules/subscribed`).then((res: any) => {
            const data = res[0] as unknown as Subscribed[];
            const ruleIds = data.filter(it => it.status === 1).map(item => item.ruleId);
            setRuleIds(ruleIds);
        })
            .catch((error:any) => {
                console.error('Error fetching data:', error);
            });
    }

    return <>
        <div className={styles.header_wrap}>
            <div className={styles.header_left}>
                <img src={logo}  alt="" className={styles.logo} onClick={toHome}/>
                <Tabs
                    activeKey={getActive()}
                    items={items}
                    centered
                    size={"large"}
                    indicatorSize={0}
                    onChange={onChange} />
            </div>
            <div className={styles.header_right}>
                <img src={telegram} alt="" className={styles.icon} onClick={toTg} />
                <img src={twitter} alt="" className={styles.icon} onClick={toTwitter} />
                <Popover content={content} title={titleText} placement="bottom" trigger="click" visible={visible}
                         onVisibleChange={handleVisibleChange}>
                    <div className={styles.bot_btn}>
                        <img src={botIcon} className={styles.bot_icon} alt=""/>
                        <p>{Number(telegramID) > 0 ? 'Telegram Bot' : 'Attach TgBot'}</p>
                    </div>
                </Popover>
                {showInfo === 2 ? <div className={styles.connectWallet_btn} onClick={connectWallet}>
                        <img src={wallet} alt=""/>
                        <div>Connect wallet</div>
                    </div>
                : <div className={styles.infoWrap} onClick={openInfo}>
                    <img src={info?.image} alt=""/>
                    {showInfo === 1 && <div className={styles.infoText}>{info.owner.slice(0, 6) + "..." + info.owner.slice(-4)}</div>}
                </div>}
                {
                    isShowModal && <div className={styles.accountWrap}>
                        <div className={styles.title}>Account</div>
                        <div className={styles.account_list}>
                            <div className={styles.account_item} onClick={toProfile}>
                                <div className={styles.account_left}>
                                    <img className={styles.account_icon} src={icon1} alt="" />
                                    <div className={styles.account_name}>Profile</div>
                                </div>
                                <img className={styles.account_right} src={rightBlack} alt="" />
                            </div>
                            <div className={styles.account_item} onClick={quitLogin}>
                                <div className={styles.account_left}>
                                    <img className={styles.account_icon} src={icon2} alt="" />
                                    <div className={styles.account_name}>Quit</div>
                                </div>
                                <img className={styles.account_right} src={rightBlack} alt="" />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <BotModal ref={botModal}/>
        </div>

    </>
})

export default Header