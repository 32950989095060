import {useEffect, useState} from "react";
import styles from "./index.module.scss";
import Header from "../components/header";
import Footer from "../components/footer";
import TgBot from "./tgBot/TgBot";
import Portfolio from "./Portfolio";
import Setting from "./Setting";
import copy from "copy-to-clipboard";
import addressIcon from './image/addressIcon.png';
import classnames from "classnames";
import {message} from "antd";
import {getRequest} from "../helper/request";
export enum ActiveType {
    Portfolio = 'Portfolio',
    TgBot = 'TgBot',
    Setting = 'Setting'
}
interface Info {
    image: string;
    username: string;
    owner: string;
    bio: string;
    email: string;
}
const Bots = () => {
    const [active, setActive] = useState<ActiveType>(ActiveType.Portfolio);
    const [info, setInfo] = useState<Info>({} as Info)
    useEffect(() => {
        getUserInfo();
    }, []);
    const RightDom = (type: ActiveType) => {
        const wallet = info?.owner && info.owner.slice(0, 6) + "..." + info.owner.slice(-4)
        const map:Map<ActiveType, () => JSX.Element> = new Map([
            [ActiveType.Portfolio, () => <Portfolio owner={info?.owner} />],
            [ActiveType.TgBot, () => <TgBot />],
            [ActiveType.Setting, () => <Setting obj={info} wallet={wallet} saveHandle={saveHandle} />],
        ])
        if (!map.has(type)) {
            return <Portfolio owner={info?.owner} />;
        }
        return map.get(type)!();
    }
    const saveHandle = () => {
        getUserInfo();
    }
    const getUserInfo = () => {
        getRequest(`/user/info`).then((res: any) => {
            const data = res[0] as unknown as Info;
            setInfo(data);
        })
            .catch((error:any) => {
                console.error('Error fetching data:', error);
            });
    }
    const copyFnc = () => {
        copy(info.owner);
        message.success('copy successfully');
    };
    return <>
        <Header />
        <div className={styles.container}>
            <div className={styles.containerLeft}>
                <div className={styles.headerImgWrap}>
                    {info?.image && <img src={info?.image} alt="" className={styles.headerImg}/>}
                </div>
                <div className={styles.userName}>{info?.username}</div>
                <div className={styles.addressWrap}>
                    {info?.owner && <div className={styles.address}>{info?.owner.slice(0, 6) + "..." + info?.owner.slice(-4)}</div>}
                    {info?.owner && <img src={addressIcon}  alt="" className={styles.copyIcon} onClick={copyFnc}/>}
                </div>
                <div className={styles.tips}>{info?.bio}</div>
                <div className={styles.tabsWrap}>
                    <div className={classnames([styles.tabItem, active === ActiveType.Portfolio && styles.activeTabItem])} onClick={() => setActive(ActiveType.Portfolio)}>
                        {active === ActiveType.Portfolio && <div className={styles.line}></div>}
                        <div className={classnames([styles.tabText, active === ActiveType.Portfolio && styles.activeTabText])}>Portfolio (Beta)</div>
                    </div>
                    {/*<div className={classnames([styles.tabItem, active === ActiveType.TgBot && styles.activeTabItem])} onClick={() => setActive(ActiveType.TgBot)}>*/}
                    {/*    {active === ActiveType.TgBot && <div className={styles.line}></div>}*/}
                    {/*    <div className={classnames([styles.tabText, active === ActiveType.TgBot && styles.activeTabText])}>Telegram Bot</div>*/}
                    {/*</div>*/}
                    <div className={classnames([styles.tabItem, active === ActiveType.Setting && styles.activeTabItem])} onClick={() => info?.owner && setActive(ActiveType.Setting)}>
                        {active === ActiveType.Setting && <div className={styles.line}></div>}
                        <div className={classnames([styles.tabText, active === ActiveType.Setting && styles.activeTabText])}>Setting</div>
                    </div>
                </div>
            </div>
            <div className={styles.containerRight}>
                {RightDom(active)}
            </div>
        </div>
        <Footer /></>
}

export default Bots