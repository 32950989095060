import styles from "./index.module.scss"
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {Button, Form, Input, message, Upload} from "antd";
import {useState} from "react";
import {postRequest} from "../../helper/request";

const { TextArea } = Input;
interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
}
interface Info {
  image: string;
  username: string;
  email: string;
  bio: string;
}
const Setting = (props: {obj: Info, wallet: string, saveHandle: () => void}) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [fields, setFields] = useState<FieldData[]>([
    { name: ['username'], value: props.obj.username },
    { name: ['email'], value: props.obj.email },
    { name: ['bio'], value: props.obj.bio },
    { name: ['wallet'], value: props.wallet },
  ]);
  const onFinish = (obj: any) => {
    postRequest(`/user/update`, {username: obj.username, email: obj.email, bio: obj.bio})
        .then((res: any) => {
          props.saveHandle();
          message.success('save successfully');
        })
        .catch((error:any) => {
          console.error('Error fetching data:', error);
        });
  }
  const onFinishFailed = (err:any) => {
    console.log(err)
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const beforeUpload = () => {

  }

  const handleChange = () => {

  }

  return <div className={styles.setting}>
    <p className={styles.setting_title}>Account Setting</p>
    <div className={styles.setting_form}>
      <Form
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 500 }}
        layout={'vertical'}
        fields={fields}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}

        autoComplete="off"
      >
        {/*<Form.Item label="Profile image" name="avatar">*/}
        {/*  <Upload*/}
        {/*    name="avatar"*/}
        {/*    listType="picture-circle"*/}
        {/*    className="avatar-uploader"*/}
        {/*    showUploadList={false}*/}
        {/*    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"*/}
        {/*    beforeUpload={beforeUpload}*/}
        {/*    onChange={handleChange}*/}
        {/*  >*/}
        {/*    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}*/}
        {/*  </Upload>*/}
        {/*</Form.Item>*/}
        <Form.Item label="Username" name="username" rules={[{ required: true, message: 'input...' }]}>
          <Input maxLength={12} showCount />
        </Form.Item>
        <Form.Item label="Bio" name="bio">
          <TextArea rows={4} placeholder="input..." maxLength={250} showCount />
        </Form.Item>
        <Form.Item label="Email Address" name="email">
          <Input placeholder="input..." />
        </Form.Item>
        <Form.Item label="Wallet Address" name="wallet">
          <Input disabled />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  </div>
}

export default Setting