import styles from './index.module.scss';
import React, {useContext, useEffect, useState} from "react";
import {Checkbox, Image, Switch} from 'antd';
import back from './image/back.png';
import selected from './image/selected.png';
import select from './image/select.png';
import add from './image/add.png';
import search from './image/search.png';
import ImgFormCarousel from "./ImgFormCarousel";
import type {CheckboxChangeEvent} from 'antd/es/checkbox';
import classnames from "classnames";
import {EditUnitType, SelectContext} from '../index';
import {UnitType} from "./SelectUnit";
import SearchInput, {Coin} from "./SearchInput/index";
import {postRequest} from "../../helper/request";
import binance from "../../Home/images/binance.svg"
import bitget from "../../Home/images/bitget.svg"
import gate from "../../Home/images/gate.io.svg"
import kucoin from "../../Home/images/kucoin.svg"
import lbank from "../../Home/images/lbank.svg"
import mexc from "../../Home/images/mexc.svg"
import uniswap from "../../Home/images/uniswap.svg"
import upbit from "../../Home/images/upbit.svg"
const imgs:ExchangeMap = {
    binance,
    bitget,
    gate,
    kucoin,
    lbank,
    mexc,
    uniswap,
    upbit,
  }
  interface ExchangeMap {
    [key: string]: string;
  }

export interface ChartSize{
    w: number,
    h: number
}
export interface Exchange{
    logo: string
    slug: string
    name: string
    exchangeName: string
    pair: string
}

interface Changes {
    isPerpetual: boolean
    listingTime: number
    name: string
    pair: string
}

export interface ShowDepth{
    type: string,
    label: string,
    value: number
}
const Tracking = (props: {type: UnitType, clickBackAdd: (tracking: UnitType) => void}) => {
    const [value, setValue] = useState(-1);
    const [chartSize, setChartSize] = useState<ChartSize>({w: 0 , h: 0});
    const [data, setData] = useState<Exchange[]>([]);
    const [selectCoin, setSelectCoin] = useState<Exchange>({} as Exchange);
    const [coinInfo, setCoinInfo] = useState<Coin>({} as Coin);
    const [isShowPrice, setIsShowPrice] = useState(false);
    const [isShowVolume, setIsShowVolume] = useState(false);
    const [type, setType] = useState('');
    const [switchType, setSwitchType] = useState(false);
    const SelectCtx = useContext(SelectContext);
    const titleObj = {
        [UnitType.Add]: '',
        [UnitType.Unit]: '',
        [UnitType.Tracking]: 'Token Tracking',
        [UnitType.Allocations]: 'Allocations',
        [UnitType.TokenPrice]: 'Token Price'
    }
    const nameObj: any = {
        binance: 'Binance',
        mexc: 'MEXC',
        lbank: 'LBank',
        gate: 'Gate.io',
        upbit: 'Upbit'
    }
    useEffect(() => {
        const coinData = props.type === UnitType.Tracking ? data : [];
        setData(coinData);
    }, [props.type])
    useEffect(() => {
        if(SelectCtx.unitEditType) {
            setSelectCoin(SelectCtx.unitEditData)
            if(SelectCtx.unitEditType === EditUnitType.TokenPrice) {
                setCoinInfo({
                    logo: SelectCtx.unitEditData.logo,
                    name: SelectCtx.unitEditData.name,
                    symbol: '',
                    slug: SelectCtx.unitEditData.slug
                });
            }
        } 
    }, [SelectCtx.unitEditType]);
    const onChange = (value: number, item: Exchange) => {
        props.type === UnitType.Tracking && setSelectCoin({
            logo: coinInfo.logo,
            name: coinInfo.name,
            slug: coinInfo.slug,
            exchangeName: item.name,
            pair: item.pair
        });
        setValue(value);
    };
    const switchChange = (checked: boolean) => {
        setSwitchType(checked);
        console.log(`switch to ${checked}`);
    };
    const checkBoxPriceChange = (e: CheckboxChangeEvent) => {
        setIsShowPrice(e.target.checked);
    };
    const checkBoxVolumeChange = (e: CheckboxChangeEvent) => {
        setIsShowVolume(e.target.checked);
    };
    const checkBoxTimeChange = (e: CheckboxChangeEvent) => {
        setIsShowPrice(e.target.checked);
    };
    const addHandle = () => {
        if((props.type === UnitType.Tracking && !selectCoin.exchangeName) || (props.type !== UnitType.Tracking && !selectCoin.name)) {
            return
        }
        addRequest();
    }
    const addRequest = () => {
        postRequest(`/boards/${SelectCtx.type}/editUnit`, {
                uid: SelectCtx.unitEditType ? SelectCtx.unitEditUid : `5464af7a-9485-447b-b837-${new Date().getTime()}`,
                top: SelectCtx.y,
                left: SelectCtx.x,
                width: chartSize.w,
                height: chartSize.h,
                type: props.type,
                config: {
                    token: coinInfo,
                    exchange: {
                        name: selectCoin.exchangeName,
                        pair: selectCoin.pair
                    },
                    tgBot: {
                        enabled: true,
                        priceChange: true,
                        volume: true,
                        depth: true
                    }
                }
            })
            .then(res => {
                SelectCtx.getSelectData(chartSize);
                props.clickBackAdd(UnitType.Add);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    const getSizeHandle = (w: number, h: number) => {
        setChartSize({w: w, h: h});
    }
    const handleChange = (coin: Coin, isEdit?: boolean, type?: UnitType) => {
        const fomatType = type || props.type;
        if(fomatType === UnitType.Tracking) {
            setData(coin.exchanges);
            setValue(-1);
            setCoinInfo({
                logo: coin.logo,
                name: coin.name,
                symbol: coin.symbol,
                slug: coin.slug
            });
            if(!isEdit) {
                setSelectCoin({} as Exchange);
            } else {
                const idx = (coin.exchanges as Changes[]).findIndex((item) => item.name === SelectCtx.unitEditData.exchangeName)
                setValue(idx);
            }
        }
        if(props.type === UnitType.TokenPrice) {
            setCoinInfo({
                logo: coin.logo,
                name: coin.name,
                symbol: coin.symbol,
                slug: coin.slug
            });
            setSelectCoin({
                logo: coin.logo,
                name: coin.name,
                slug: coin.slug,
                exchangeName: '',
                pair: ''
            } as Exchange);
        }
    }
    return (
        <div>
            {!SelectCtx.unitEditType && <div className={styles.backWrap} onClick={() => props.clickBackAdd(UnitType.Unit)}>
                <Image preview={false} className={styles.backImg} src={back}></Image>
            </div>}
            <div className={styles.title}>{titleObj[props.type]}</div>
            <div className={styles.tips}>Displays the latest market data for a coin in real-time</div>
            <div className={styles.middleWrap}>
                <ImgFormCarousel
                    isShowPrice={true}
                    isShowVolume={true}
                    selectCoin={selectCoin}
                    type={props.type}
                    getSize={getSizeHandle}></ImgFormCarousel>
                <div className={styles.carouselRightWrap}>
                    <div className={styles.searchWrap}>
                        <div className={styles.searchTitle}>Select a token</div>
                        <div className={styles.iptWrap}>
                            <Image preview={false} className={styles.searchImg} src={search}></Image>
                            <SearchInput coin={SelectCtx.unitEditType ? SelectCtx?.unitEditData?.name : ''} 
                            type={props.type} handleChange={handleChange} />
                        </div>
                        {props.type === UnitType.Tracking && <div className={styles.radioBoxWrap}>
                            <div className={styles.selectTitle}>Select exchange</div>
                            <div className={styles.radioBoxList}>
                                {
                                    data?.map((item, index) => (
                                        <div className={styles.radioItem} key={index}>
                                            <div className={styles.radioRightWrap}>
                                                {item.logo !== '' && <div className={styles.radioIcon}>
                                                   <Image preview={false} className={styles.selectImg} src={imgs[item.name.toLowerCase()]}></Image>
                                                </div>}
                                                <div className={styles.radioText}>{props.type === UnitType.Tracking ? nameObj[item.name] || item.name : item.name}</div>
                                            </div>
                                            <Image preview={false} className={styles.selectImg} onClick={() => onChange(index, item)}
                                                   src={value === index ? selected : select}></Image>
                                        </div>))
                                }
                            </div>
                        </div>}
                        <div className={styles.checkBoxWrap}>
                            <div className={styles.selectTitle}>Telegram Bot</div>
                            <div className={styles.switchWrap}>
                                <Switch checked={switchType} onChange={switchChange} className={styles.switch} />
                            </div>
                        </div>
                        <div className={styles.checkBoxTips}>Set your reminder metrics</div>
                        {props.type === UnitType.Tracking ?
                            <div className={styles.tgCheckBoxWrap}>
                                <div className={classnames(styles.tgWrap, styles.tgTitleWrap)}>
                                    <div className={styles.tgItem}>Metrics</div>
                                    <div className={styles.tgItem}>Numerical value</div>
                                    <div className={styles.tgItem}>Period</div>
                                </div>
                                <div className={styles.tgWrap}>
                                    <div className={styles.tgItem}>24h Price Change</div>
                                    <div className={classnames([styles.tgItem, styles.tgItemData])}>±5%</div>
                                    <div className={classnames([styles.tgItem, styles.tgItem2, styles.tgItemData])}>10min</div>
                                    <div className={styles.tgItem3}><Checkbox disabled={!switchType} checked={isShowPrice} onChange={checkBoxPriceChange}></Checkbox></div>
                                </div>
                                <div className={styles.tgWrap}>
                                    <div className={styles.tgItem}>24h Volume Change</div>
                                    <div className={classnames([styles.tgItem, styles.tgItemData])}>±5%</div>
                                    <div className={classnames([styles.tgItem, styles.tgItem2, styles.tgItemData])}>10min</div>
                                    <div className={styles.tgItem3}><Checkbox disabled={!switchType} checked={isShowVolume} onChange={checkBoxVolumeChange}></Checkbox></div>
                                </div>
                                {/* <div className={styles.tgWrap}>
                                    <div className={styles.tgItem}>Depth(±2%)</div>
                                    <div className={classnames([styles.tgItem, styles.tgItemData])}>
                                        <span style={{color: 'rgba(35, 196, 72, 1)'}}>$10,000</span>/
                                        <span style={{color: 'rgba(249, 52, 53, 1)'}}>$10,000</span>
                                    </div>
                                    <div className={classnames([styles.tgItem, styles.tgItem2, styles.tgItemData])}>-</div>
                                </div> */}
                            </div>
                            : <div className={styles.tgCheckBoxWrap}>
                                <div className={styles.tgWrap}>
                                    <div className={classnames([styles.tgItem, styles.tgItem4])}>Metrics</div>
                                    <div className={styles.tgItem}>Reminder time</div>
                                </div>
                                <div className={styles.tgWrap}>
                                    <div className={classnames([styles.tgItem, styles.tgItem4])}>Upcoming Event</div>
                                    <div className={classnames([styles.tgItem, styles.tgItem5, styles.tgItemData])}>7D/24H/1H/5Min</div>
                                    <div className={styles.tgItem3}><Checkbox onChange={checkBoxTimeChange}></Checkbox></div>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
            <div className={classnames([styles.addWrap, ((props.type === UnitType.Tracking && !selectCoin.exchangeName) || (props.type !== UnitType.Tracking && !selectCoin.name))
                && styles.addWrapActive])} onClick={addHandle}>
                {SelectCtx.unitEditType ? <div className={styles.addText}>Edit</div> : <>
                    <Image preview={false} className={styles.addImg} src={add}></Image>
                    <div className={styles.addText}>Add</div>
                </>}
            </div>
        </div>
    );
};

export default Tracking;