export const getHomeData = () => {
    const data = {
        "_id": {
            "$oid": "658b9cdf83ad421bfe97d802"
        },
        "owner": "0x5c8d91e93a2c00cfb510e7e27055513de3251f45",
        "uid": "home",
        "name": "default",
        "units": [
            {
                "uid": "5464af7a-9485-447b-b837-1703648523089",
                "top": 0,
                "left": 3,
                "width": 3,
                "height": 2,
                "type": "tokenTracking",
                "config": {
                    "token": {
                        "logo": "https://assets.coingecko.com/coins/images/825/standard/bnb-icon2_2x.png?1696501970",
                        "name": "BNB",
                        "symbol": "BNB",
                        "slug": "bnb"
                    },
                    "exchange": {
                        "name": "binance",
                        "pair": "BNBUSDT"
                    },
                    "tgBot": {
                        "enabled": true,
                        "priceChange": true,
                        "volume": true,
                        "depth": true
                    }
                }
            },
            {
                "uid": "5464af7a-9485-447b-b837-1703648559231",
                "top": 2,
                "left": 3,
                "width": 1,
                "height": 1,
                "type": "tokenPrice",
                "config": {
                    "token": {
                        "logo": "https://assets.coingecko.com/coins/images/1/large/bitcoin.png",
                        "name": "Bitcoin",
                        "symbol": "BTC",
                        "slug": "bitcoin"
                    },
                    "exchange": {
                        "name": "",
                        "pair": ""
                    },
                    "tgBot": {
                        "enabled": true,
                        "priceChange": true,
                        "volume": true,
                        "depth": true
                    }
                }
            },
            {
                "uid": "5464af7a-9485-447b-b837-1703648565922",
                "top": 2,
                "left": 4,
                "width": 1,
                "height": 1,
                "type": "tokenPrice",
                "config": {
                    "token": {
                        "logo": "https://assets.coingecko.com/coins/images/4128/standard/solana.png?1696504756",
                        "name": "Solana",
                        "symbol": "SOL",
                        "slug": "solana"
                    },
                    "exchange": {
                        "name": "",
                        "pair": ""
                    },
                    "tgBot": {
                        "enabled": true,
                        "priceChange": true,
                        "volume": true,
                        "depth": true
                    }
                }
            },
            {
                "uid": "5464af7a-9485-447b-b837-1703648575531",
                "top": 2,
                "left": 5,
                "width": 1,
                "height": 1,
                "type": "tokenPrice",
                "config": {
                    "token": {
                        "logo": "https://assets.coingecko.com/coins/images/32528/standard/memecoin_%282%29.png?1698912168",
                        "name": "Memecoin",
                        "symbol": "MEME",
                        "slug": "meme"
                    },
                    "exchange": {
                        "name": "",
                        "pair": ""
                    },
                    "tgBot": {
                        "enabled": true,
                        "priceChange": true,
                        "volume": true,
                        "depth": true
                    }
                }
            },
            {
                "uid": "5464af7a-9485-447b-b837-1703648598190",
                "top": 3,
                "left": 3,
                "width": 1,
                "height": 1,
                "type": "tokenPrice",
                "config": {
                    "token": {
                        "logo": "https://space.unlocks.app/tokens/arbitrum.png",
                        "name": "Arbitrum",
                        "symbol": "ARB",
                        "slug": "arbitrum"
                    },
                    "exchange": {
                        "name": "",
                        "pair": ""
                    },
                    "tgBot": {
                        "enabled": true,
                        "priceChange": true,
                        "volume": true,
                        "depth": true
                    }
                }
            },
            {
                "uid": "5464af7a-9485-447b-b837-1703648609319",
                "top": 3,
                "left": 4,
                "width": 1,
                "height": 1,
                "type": "tokenPrice",
                "config": {
                    "token": {
                        "logo": "https://space.unlocks.app/tokens/optimism.png",
                        "name": "Optimism",
                        "symbol": "OP",
                        "slug": "optimism"
                    },
                    "exchange": {
                        "name": "",
                        "pair": ""
                    },
                    "tgBot": {
                        "enabled": true,
                        "priceChange": true,
                        "volume": true,
                        "depth": true
                    }
                }
            },
            {
                "uid": "5464af7a-9485-447b-b837-1703648621597",
                "top": 3,
                "left": 5,
                "width": 1,
                "height": 1,
                "type": "tokenPrice",
                "config": {
                    "token": {
                        "logo": "https://assets.coingecko.com/coins/images/17980/standard/ton_symbol.png?1696517498",
                        "name": "Toncoin",
                        "symbol": "TON",
                        "slug": "toncoin"
                    },
                    "exchange": {
                        "name": "",
                        "pair": ""
                    },
                    "tgBot": {
                        "enabled": true,
                        "priceChange": true,
                        "volume": true,
                        "depth": true
                    }
                }
            },
            {
                "uid": "5464af7a-9485-447b-b837-1703648765523",
                "top": 0,
                "left": 0,
                "width": 3,
                "height": 2,
                "type": "tokenTracking",
                "config": {
                    "token": {
                        "logo": "https://assets.coingecko.com/coins/images/4128/standard/solana.png?1696504756",
                        "name": "Solana",
                        "symbol": "SOL",
                        "slug": "solana"
                    },
                    "exchange": {
                        "name": "binance",
                        "pair": "SOLUSDT"
                    },
                    "tgBot": {
                        "enabled": true,
                        "priceChange": true,
                        "volume": true,
                        "depth": true
                    }
                }
            },
            {
                "uid": "5464af7a-9485-447b-b837-1703648825096",
                "top": 0,
                "left": 0,
                "width": 3,
                "height": 2,
                "type": "tokenTracking",
                "config": {
                    "token": {
                        "logo": "https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628",
                        "name": "Ethereum",
                        "symbol": "ETH",
                        "slug": "ethereum"
                    },
                    "exchange": {
                        "name": "mexc",
                        "pair": "ETHUSDT"
                    },
                    "tgBot": {
                        "enabled": true,
                        "priceChange": true,
                        "volume": true,
                        "depth": true
                    }
                }
            }
        ],
        "ctime": 1703648479,
        "mtime": 1703648915,
        "__v": 0
    }
    return data;
}
