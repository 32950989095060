import styles from './index.module.scss';
import React from "react";
import classnames from "classnames";
import allocation from "../../images/allocation.png";
import cliff from "../../images/cliff.png";
import linear from "../../images/linear.png";
import {Image} from "antd";
import {EventType} from "../../index"
import {formatAmount} from "../../../utils/format"
const UpComing = (props: {title?: string, locks: EventType, price: string, cirSupply: string, marketCap: string, page?: string, width?: number, height?: number}) => {
    const formattedDate = (timestamp: number) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份是从 0 开始的，需要加 1
        const day = ('0' + date.getDate()).slice(-2);
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const seconds = ('0' + date.getSeconds()).slice(-2);

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    return (
        <div className={styles.upcomingBlockWrap} style={{width: props.width + 'px', height: props.height + 'px'}}>
            <div className={classnames(styles.upcomingTopWrap, props.page === 'home' && styles.homeUpcomingTopWrap)}>
                <div className={classnames(styles.upcomingTopTitle, props.page === 'home' && styles.homeUpcomingTopTitle)}>{props.title}</div>
                <div className={classnames(styles.upcomingTopTime, props.page === 'home' && styles.homeUpcomingTopTime)}>
                    {formattedDate(props.locks.unlockTime * 1000)}
                </div>
            </div>
            <div className={styles.unlockWrap}>
                <div
                    className={classnames(styles.upcomingInfoWrap, props.page === 'home' && styles.homeUpcomingInfoWrap)}>
                    <div className={classnames(styles.upcomingIcon, props.page === 'home' && styles.homeUpcomingIcon)}>
                        {props.locks && +props.locks.allocations[0].cliffUnlockAmount > 0 ?
                            <Image preview={false} className={styles.cliffImg} src={cliff}></Image> :
                            <Image preview={false} className={styles.linearImg} src={linear}></Image>}
                    </div>
                    <div
                        className={classnames(styles.upcomingContentWrap, props.page === 'home' && styles.homeUpcomingContentWrap)}>
                        <div className={styles.unlockName}>{props.locks && +props.locks.allocations[0].cliffUnlockAmount > 0 ? 'Clif Unlock' : 'Linear Unlock'}</div>
                        <div
                            className={classnames(styles.upcomingContentTitle, props.page === 'home' && styles.homeUpcomingContentTitle)}>
                            ${props.locks && formatAmount(+props.locks.allocations[0].cliffUnlockAmount > 0 ? +props.locks.cliffUnlockAmount : +props.locks.linearUnlockAmountPerDay)} TIA
                        </div>
                        <div className={styles.cirSupply}>
                            {((+props.locks.allocations[0].cliffUnlockAmount > 0 ? +props.locks.cliffUnlockAmount : +props.locks.linearUnlockAmountPerDay) / +props.cirSupply).toFixed(2)}% of Cir. supply
                        </div>
                    </div>
                </div>
                <div
                    className={classnames(styles.upcomingContentMoney, props.page === 'home' && styles.homeUpcomingContentMoney)}>
                    {/*${formatNumber(props.locks && +props.locks.cliffUnlockAmount * +props.price / 1000000) || ''}m*/}
                </div>
            </div>
            <div
                className={classnames(styles.allocationTitleWrap, props.page === 'home' && styles.homeAllocationTitleWrap)}>
                <div className={styles.allocationLeftWrap}>
                    <div
                        className={classnames(styles.allocationLeftIcon, props.page === 'home' && styles.homeAllocationLeftIcon)}>
                        <Image preview={false} className={styles.allocationImg} src={allocation}></Image>
                    </div>
                    <div className={classnames(styles.allocationLeftTitle, props.page === 'home' && styles.homeAllocationLeftTitle)}>Allocation
                    </div>
                </div>
                <div className={classnames(styles.allocationNumber, props.page === 'home' && styles.homeAllocationNumber)}>
                    {props.locks && props.locks.allocations.length}
                </div>
            </div>
            <div className={classnames(styles.allocationList, props.page === 'home' && styles.homeAllocationList)}>
                {
                    props.locks && props.locks.allocations.map((item, index) => (
                        <div
                            className={classnames(styles.allocationItem, props.page === 'home' && styles.homeAllocationItem)}
                            key={index}>
                        <div className={classnames(styles.allocationItemType, props.page === 'home' && styles.homeAllocationItemType)}
                            style={{background: item.color}}></div>
                            <div className={styles.allocationTopWrap}>
                                <div className={classnames(styles.allocationTopTitle, props.page === 'home' && styles.homeAllocationTopTitle)}>{item.name}</div>
                                <div className={classnames(styles.allocationTopMoney, props.page === 'home' && styles.homeAllocationTopMoney)}>
                                    ${formatAmount((+item.cliffUnlockAmount || +item.linearUnlockAmountPerDay) * +props.price)}
                                </div>
                            </div>
                            <div className={classnames(styles.allocationMiddleWrap, props.page === 'home' && styles.homeAllocationMiddleWrap)}>
                                <div className={classnames(styles.allocationMiddleWrap, props.page === 'home' && styles.homeAllocationMiddleWrap)}>
                                    <div className={classnames(styles.allocationMiddleNumber, props.page === 'home' && styles.homeAllocationMiddleNumber)}>
                                        {formatAmount(+item.cliffUnlockAmount || +item.linearUnlockAmountPerDay)}
                                    </div>
                                    <span className={classnames(styles.allocationMiddleTips, props.page === 'home' && styles.homeAllocationMiddleTips)}>APE</span>
                                </div>
                                <div className={classnames(styles.allocationMiddleMoney, props.page === 'home' && styles.homeAllocationMiddleMoney)}>
                                    {(+item.cliffUnlockAmount/+props.marketCap).toFixed(2)}% of M.Cap
                                </div>
                            </div>
                            <div className={classnames(styles.allocationBottomWrap, props.page === 'home' && styles.homeAllocationBottomWrap)}>
                                {(+item.cliffUnlockAmount/+props.cirSupply).toFixed(2)}% Cir. Supply
                            </div>
                        </div>))
                }

            </div>
        </div>
    );
};

export default UpComing;