import {useState} from "react";
import styles from "./index.module.scss"
import botRight from '../image/botRight.png';
import {Input, Switch, notification} from 'antd';
import DeleteModel from "../deleteModel";
import classnames from "classnames";
import icon from '../image/icon.png';
const TgBot = () => {
    const [code, setCode] = useState('');
    const [nextTemp, setNextTemp] = useState(true);
    const [api, contextHolder] = notification.useNotification();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCode(e.target.value);
    };
    const switchChange1 = (checked: boolean) => {
        setNextTemp(checked);
    };
    const deleteHandle = () => {
        setIsModalOpen(true);
    }
    const handleOk = () => {
        api.open({
            message: 'Configuration deleted',
            placement: 'top'
        });
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <div className={styles.container}>
            <div className={styles.containerLeft}>
                <div className={styles.title}>Telegram Bot</div>
                <div className={styles.tips}>By linking the Telegram bot you can subscribe to data on the HexMet dashboard. Before linking,
                 you must first add the telegram robot and obtain the connection code from telegram to connect.</div>
                 {!nextTemp && <div className={styles.temp1}>
                    <div className={styles.codeTitleWrap}>
                        <div className={styles.codeTitle}>Code</div>
                        <div className={styles.askBot}>Ask Bot</div>
                    </div>
                    <div className={styles.codeInputWrap}>
                        <Input value={code} onChange={handleInputChange} className={styles.codeInput} bordered={false} type="text" placeholder="Please enter code…" />
                    </div>
                    <div className={classnames(styles.codeBtn, code && styles.codeBtnActive)} onClick={() => setNextTemp(true)}>Associate</div>
                </div>}
                {nextTemp && <div className={styles.temp2}>
                    <div className={styles.switchList}>
                        <div className={styles.switchItem}>
                            <div className={styles.switchTitle}>Configured information</div>
                            <Switch defaultChecked onChange={switchChange1} className={styles.switch} />
                        </div>
                        <div className={styles.switchItem2}>
                            <div className={styles.titleWrap}>
                                <img src={icon}  alt="" className={styles.icon} onClick={deleteHandle}/>
                                <div className={styles.switchTitle}>Token Tracking</div>
                            </div>
                            <Switch defaultChecked onChange={switchChange1} className={styles.switch} />
                        </div>
                        <div className={styles.switchItem2}>
                            <div className={styles.titleWrap}>
                                <img src={icon}  alt="" className={styles.icon}/>
                                <div className={styles.switchTitle}>Token Tracking</div>
                            </div>
                            <Switch defaultChecked onChange={switchChange1} className={styles.switch} />
                        </div>
                    </div>
                </div>}
            </div>
            <img src={botRight} alt="" className={styles.containerRight}/>
            {contextHolder}
            <DeleteModel isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}></DeleteModel>
        </div>
    );
};

export default TgBot;