import {useState, useRef, useEffect} from "react";
import styles from "./index.module.scss"
import check2 from './image/check2.png';
import {Table, Input, Image} from 'antd';
import Chart from "./Chart";
import classnames from "classnames";
import addWhite from "./image/add.png";
import BotModal from "./BotModal";
import {getRequest} from "../helper/request";

const columns = [{
    title: 'Name',
    dataIndex: 'symbol',
    key: 'symbol',
  },{
    with: 300,
    title: 'Price(24H %)',
    dataIndex: 'price',
    key: 'price',
    render: (_:any, it:any) => {
        return <div className={styles.card_item_r}>
            <div className={styles.home_card_item_r_text}>$ {Number(Number(it.prevClosePrice).toFixed(+it.prevClosePrice > 1 ? 2 : 4)).toLocaleString('en')}</div>
            <span className={classnames([styles.home_card_item_r_text, it.priceChangePercent > 0 ? styles.up : styles.down])}>
            ({it.priceChangePercent > 0 && '+'}{Number(it.priceChangePercent).toFixed(2)}%)
            </span>
        </div>
    },
  },{
    title: 'Total Balance',
    dataIndex: 'Total Balance',
    key: 'Total Balance',
    render: () => {
        return <span className={styles.cell200} >--</span>
    },
  },{
    title: 'Total Debt',
    dataIndex: 'Total Debt',
    key: 'Total Debt',
    render: () => {
        return <span className={styles.cell200} >--</span>
    },
  },{
    title: 'Outstanding Interest',
    dataIndex: 'Outstanding interest',
    key: 'Outstanding interest',
    render: () => {
        return <span className={styles.cell200} >--</span>
    },
  },{
    title: 'Margin Level',
    dataIndex: 'Margin Level',
    key: 'Margin Level',
    render: () => {
        return <span className={styles.cell200} >--</span>
    },
  }]
  interface IBots {
    symbol: string;
    price: string;
    maxSupply: string;
    cirSupply: string;
    next7dEmission: string;
  }
  interface Data {
    symbol: string;
    exchanges: any;
  }
  interface Price {
    prevClosePrice: string;
    priceChangePercent: string;
  }
const Portfolio = (props: {owner: string}) => {
    const [activeCheck, setActiveCheck] = useState(0);
    const [data, setData] = useState<IBots[]>([]);
    const botModal = useRef<any>(null);
    useEffect(() => {
        let jwt = localStorage.getItem('jwt') || '';
        if(!jwt) return
        loadHandle();
    }, [])
    const loadHandle = () => {
        try {
            let strData = localStorage.getItem('tokenData') || '';
            let data = [];
            if(strData) {
                data = JSON.parse(strData);
            }
            getData(data);
        } catch (error) {
            console.log(error);
        }
    }
    const addToken = () => {
        botModal.current && botModal.current.showModal()
    }
    const getData = async (data: Data[]) => {
        let datas: any = [];
        
        await Promise.all(
            data.map(async item => {
                if(!item?.exchanges) {
                    return
                }
                const it: any = await getTickerRequest(item.symbol, item?.exchanges[0]?.name, item);
                datas.push(it);
            })
        );
        setData(datas);
    }
    const getTickerRequest =  (symbol: string, exchange: string, it: any) => {
        return new Promise((resolve, reject) => {
            getRequest('/exchange/ticker/24hr', { params: { exchange: exchange, symbol: symbol + 'USDT' } })
            .then( res => {
                const data = res[0] as unknown as Price;
                it.prevClosePrice = data.prevClosePrice;
                it.priceChangePercent = data.priceChangePercent;
                resolve(it);
            })
            .catch(error => {
                console.error('Error fetching data', error);
            });
        })
    }
    const handleOk = (data: any) => {
        getData(data);
    }
    return (
        <div>
            <div className={styles.titleWrap}>
                <div className={styles.containerRightTitle}>Portfolio</div>
                {props.owner && <div className={styles.addWrap} onClick={addToken}>
                    <Image preview={false} className={styles.addImg} src={addWhite}></Image>
                    <div className={styles.addText}>IMPORT TOKEN</div>
                </div>}
            </div>
            <div className={styles.searchWrsp}>
                {/* <div className={styles.searchItem}>
                    <img src={searchIcon} alt="" className={styles.searchIcon}/>
                    <Input className={styles.searchInput} bordered={false} type="text" placeholder="Search" />
                </div> */}
                {/* <div className={styles.checkWrap}>
                    <div className={classnames([styles.checkItem, activeCheck === 0 && styles.activeCheckItem])} onClick={() => setActiveCheck(0)}>
                        <img src={check1} alt="" className={styles.checkImg}/>
                    </div>
                    <div className={classnames([styles.checkItem, activeCheck === 1 && styles.activeCheckItem])} onClick={() => setActiveCheck(1)}>
                        <img src={check2} alt="" className={styles.checkImg}/>
                    </div>
                </div> */}
            </div>
            {
                activeCheck === 1 ? <div className={styles.botsListWrap}>
                    {
                        new Array(10).fill(0).map((item, index) => (<div className={styles.botsItemWrap}>
                            <div className={styles.botsInfo}>
                                <div className={styles.botsInfoLeft}>
                                    <img src={check2} alt="" className={styles.botsLogo}/>
                                    <div className={styles.botsName}>Celestia(TIA)</div>
                                </div>
                                <div className={styles.botsInfoRight}>
                                    <div className={styles.botsPrice}>$23.25m</div>
                                    <div className={classnames([styles.botsChange, styles.up])}>(+1.00%)</div>
                                </div>
                            </div>
                            <Chart />
                            <div className={styles.chartList}>
                                <div className={styles.chartItem}>
                                    <div className={styles.chartTitle}>Total Balance</div>
                                    <div className={styles.chartNum}>100,220 TIA</div>
                                </div>
                                <div className={styles.chartItem}>
                                    <div className={styles.chartTitle}>Total Debt</div>
                                    <div className={styles.chartNum}>100,220 TIA</div>
                                </div>
                                <div className={styles.chartItem}>
                                    <div className={styles.chartTitle}>Outstanding Interest</div>
                                    <div className={styles.chartNum}>100,220 TIA</div>
                                </div>
                                <div className={styles.chartItem}>
                                    <div className={styles.chartTitle}>Margin Level</div>
                                    <div className={classnames([styles.chartNum, styles.chartNumGreen])}>62.06</div>
                                </div>
                            </div>
                        </div>))
                    }
                </div>
                : <div className={styles.tableWrap}>
                    <Table columns={columns} dataSource={data} pagination={{ position: ["none", "bottomCenter"] }} />
                </div>
            }
            <BotModal handleOk={handleOk} ref={botModal}/>
        </div>
    );
};

export default Portfolio;