import React, {useEffect, useState, createContext, useRef} from 'react';
import GridLayout from 'react-grid-layout';
import styles from './index.module.scss';
import SelectGroupModel from "./components/SelectGroupModel";
import Header from "../components/header";
import Footer from "../components/footer";
import TrackingChart from "./components/TrackingChart";
import UpcomingUnlock from "./components/UpcomingUnlock";
import Upcoming from "./components/Upcoming";
import TokenPrice from "./components/TokenPrice";
import noSelectAdd from "./components/image/noSelectAdd.png";
import deleteICon from "./image/delete.png";
import editIcon from "./image/edit.png";
import edit from "./image/editUnit.png";
import change from "./image/change.png";
import addWhite from "./image/add_white.png"
import leftBlack from "./image/left_black.png"
import rightBlack from "./image/right_black.png"
import homeIcon from "./image/home_icon.png"
import {Image, message} from "antd";
import {postRequest} from "../helper/request";
import {ChartSize} from "./components/Tracking";
import classnames from "classnames";
import {getHomeData} from "../utils/dashboard";
export enum EditUnitType {
    UnSet = '',
    Tracking = 'tokenTracking',
    TokenPrice = 'tokenPrice'
}
export const SelectContext = createContext<{
    x: number,
    y: number,
    type: string,
    unitEditUid: string,
    unitEditWidth: number,
    unitEditHeight: number,
    unitEditType: EditUnitType,
    unitEditData: SelectCoin,
    getSelectData: (chartSize: ChartSize) => void, // 多选
}>({
    x: 0,
    y: 0,
    type: '',
    unitEditUid: '',
    unitEditWidth: 0,
    unitEditHeight: 0,
    unitEditData: {} as SelectCoin,
    unitEditType: EditUnitType.UnSet,
    getSelectData: () => {}
});
export enum Type {
    Tracking = 'tokenTracking',
    Upcoming = 'upcoming',
    UpcomingUnlock = 'upcomingUnlock',
    TokenPrice = 'tokenPrice',
}
interface Position{
    x: number,
    y: number
}
interface ChartPositionSize{
    x: number,
    y: number,
    i: string,
    w: number,
    h: number
}
interface Exchange{
    name: string;
    pair: string;
}
interface SelectCoin {
    logo: string
    slug: string
    name: string
    exchangeName: string
    pair: string
}
interface TgBot{
    depth: boolean;
    enabled: boolean;
    priceChange: boolean;
    volume: boolean;
}
interface Token{
    logo: string;
    name: string;
    slug: string;
    symbol: string;
}
interface Config{
    exchange: Exchange;
    tgBot: TgBot;
    token: Token;
}
interface Unit{
    height: number;
    left: number;
    top: number;
    type: Type;
    uid: string;
    width: number;
    config: Config;
}
interface Boards{
    ctime: number;
    mtime: number;
    name: string;
    owner: string;
    uid: string;
    units: Unit[];
}
const ObservationGroup = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [unitEditType, setUnitEditType] = useState<EditUnitType>(EditUnitType.UnSet);
    const [layout, setLayout] = useState<ChartPositionSize[]>([]);
    const [boards, setBoards] = useState<Boards[]>([]);
    const [boardsIndex, setBoardsIndex] = useState<number>(0);
    const [position, setPosition] = useState<Position>({x: 0, y: 0});
    const [layoutArr, setLayoutArr] = useState(36)
    const layoutContent = useRef<HTMLDivElement>(null)
    const bgWrap = useRef<HTMLDivElement>(null)
    const [activeNav, setActiveNav] = useState('home');
    const [canEdit, setCanEdit] = useState(false)
    const [uid, setUid] = useState('');
    const [deleteUnitUid, setDeleteUnitUid] = useState('');
    const [moveLayout, setMoveLayout] = useState<any[]>([]);
    const [unitEditData, setUnitEditData] = useState({} as SelectCoin);
    const [unitEditUid, setUnitEditUid] = useState('');
    const [unitEditWidth, setUnitEditWidth] = useState<number>(0);
    const [unitEditHeight, setUnitEditHeight] = useState<number>(0);
    const scrollX = useRef<HTMLDivElement>(null)
    const header = useRef<any>(null);
    useEffect(() => {
        const uid = localStorage.getItem('uid') || 'home';
        const index = Number(localStorage.getItem('boardsIndex')) || 0;
        getBoards(index);
        const jwt = localStorage.getItem('jwt');
        if(!jwt) {
            setActiveNav('home');
            return
        }
        setActiveNav(uid);
        setBoardsIndex(index);
    }, []);
    useEffect(() => {
        if(!uid) return
        const curr = moveLayout.find(item => item.i === uid);
        if(!curr) return
        if(uid === deleteUnitUid) return
        editUnitRequest(curr.x, curr.y);
    }, [uid]);
    useEffect(() => {
        if(unitEditType === EditUnitType.UnSet) return;
        setIsModalOpen(true);
    }, [unitEditType])
    const getBoards = (index?: number) => {
        postRequest('/boards')
            .then((res: any) => {
                const homeData = getHomeData();
                const data = (res[0] || [homeData]) as unknown as Boards[];
                const jwt = localStorage.getItem('jwt');
                console.log(jwt);
                if(!jwt) {
                    index = 0;
                    setBoards([homeData as Boards]);
                } else {
                    const uid = localStorage.getItem('uid') || activeNav;
                    if(uid === 'home') {
                        index = 0;
                        data[0].units = homeData.units as Unit[];
                    }
                    setBoards(data);
                }
                let layoutData: ChartPositionSize[] = [];
                data[index || boardsIndex || 0].units.map(item => {
                    layoutData.push({
                        x: item.left,
                        y: item.top,
                        i: item.uid,
                        w: item.width,
                        h: item.height * 2
                    })
                })
                setLayout(layoutData);
            })
            .catch((error:any) => {
                console.error('Error fetching data:', error);
            });
    }
    const deleteRequest = (uid: string) => {
        setDeleteUnitUid(uid);
        postRequest(`/boards/${boards[boardsIndex].uid}/deleteUnit`, {uid: uid})
            .then(res => {
                boards[boardsIndex].units = boards[boardsIndex].units.filter(item => item.uid !== uid);
                navClick(boards[boardsIndex].uid, boardsIndex);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    const chartDom = (item: Unit) => {
        const config = item.config;
        const coin = {
            logo: config.token?.logo,
            name: config.token?.name,
            slug: config.token?.slug,
            symbol: config.token?.symbol,
            exchangeName: config.exchange?.name,
            pair: config.exchange?.pair
        };
        const map:Map<Type, () => JSX.Element> = new Map([
            [Type.Tracking, () => <TrackingChart selectCoin={coin} page={'home'} currSlider={item.width} />],
            [Type.Upcoming, () => <Upcoming locks={[]} page={'home'} />],
            [Type.UpcomingUnlock, () => <UpcomingUnlock locks={[]} page={'home'} />],
            [Type.TokenPrice, () => <TokenPrice selectCoin={coin} page={'home'} />],
        ])
        if (!map.has(item.type)) {
            console.log(item)
            return <></>;
        }
        return map.get(item.type)!();
    }

    // 获取屏幕宽度
    const screenWidth = window.innerWidth - 80;
    const editUnit = (item: Unit) => {
        const config = item.config;
        const coin = {
            logo: config.token?.logo,
            name: config.token?.name,
            slug: config.token?.slug,
            symbol: config.token?.symbol,
            exchangeName: config.exchange?.name,
            pair: config.exchange?.pair
        };
        if(item.type === Type.Tracking) {
            editTrackingChart(coin, item.uid, item.left, item.top, item.width, item.height);
        } else {
            editTokenPrice(coin, item.uid, item.left, item.top);
        }
    }
    const editTrackingChart = (coin: SelectCoin, uid: string, x: number, y: number, width: number, height: number) => {
        if(activeNav === 'home') return
        setPosition({x, y});
        setUnitEditUid(uid);
        setUnitEditWidth(width);
        setUnitEditHeight(height);
        setUnitEditData(coin);
        setUnitEditType(EditUnitType.Tracking);
    }
    const editTokenPrice = (coin: SelectCoin, uid: string, x: number, y: number) => {
        if(activeNav === 'home') return
        setPosition({x, y});
        setUnitEditUid(uid);
        setUnitEditData(coin);
        setUnitEditType(EditUnitType.TokenPrice);
    }

    const handleOk = () => {
        setIsModalOpen(false);
        setUnitEditUid('');
        setUnitEditType(EditUnitType.UnSet);
        setUnitEditData({} as SelectCoin);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setUnitEditUid('');
        setUnitEditType(EditUnitType.UnSet);
        setUnitEditData({} as SelectCoin);
    };
    const onRemoveUtils = (uid: string, index: number) => {
        deleteRequest(uid);
    }
    const getSelectData = (val: ChartSize) => {
        getBoards();
    }
    const addHandle = (index: number) => {
        const jwt = localStorage.getItem('jwt');
        if(!jwt) {
            message.error('Please Connect Wallet');
            return
        }
        const x = index % 6;
        const y = Math.floor(index / 6);
        setPosition({x, y});
        setIsModalOpen(true);
    }
    const editHandle = () => {
        setCanEdit(!canEdit)
    }
    const updateBoards = (name: string) => {
        if(!name) {
            boards[boardsIndex].name = '';
            console.log(boards)
            setBoards([...boards]);
            return
        }
        const uid = boards[boardsIndex].uid;
        postRequest(`/boards/${uid}/update`, {uid: uid, name: name})
            .then(res => {
                boards[boardsIndex].name = name;
                setBoards([...boards]);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }
    const deleteBoards = (uid: string) => {
        postRequest(`/boards/${uid}/delete`, {uid: uid})
            .then(res => {
                const newBoards = boards.filter(item => item.uid !== uid);
                setBoards(newBoards);
                if(uid === activeNav) {
                    setCanEdit(false);
                    navClick('home', 0);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }
    const onLayoutChange = (layout: any) => {
        const layoutGridHeight = layoutContent.current!.offsetHeight
        const layoutBgWrapHeight = bgWrap.current!.offsetHeight
        if ((layoutBgWrapHeight - layoutGridHeight) < 216) {
            let abs = layoutBgWrapHeight - layoutGridHeight
            if (abs < 0) {
                abs = Math.abs(abs) + 216
            }
            let count = Math.ceil(abs / 216) * 6;
            setLayoutArr(layoutArr + count)
            return;
        }
        if ((layoutBgWrapHeight - layoutGridHeight) > 216) {
            let abs = layoutBgWrapHeight - layoutGridHeight - 216
            let count = Math.floor(abs / 216) * 6
            if(count > 12) {
                count = 12;
            }
            setLayoutArr(layoutArr - count)
        }
        setMoveLayout(layout);
    }
    const onDragStop = (allLayout: any, layout: any) => {
        setUid(layout.i);
    }
    const editUnitRequest = (x: number, y: number) => {
        let unit = boards[boardsIndex].units.find(item => item.uid === uid) || {left: 0, top: 0};
        unit.left = x;
        unit.top = y;
        postRequest(`/boards/${boards[boardsIndex].uid}/editUnit`, unit)
            .then(res => {
                setUid('');
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    const addDashBoard = () => {
        const jwt = localStorage.getItem('jwt');
        if(!jwt) {
            header.current && header.current.connectWallet()
            return
        }
        const uid = `5464af7a-9485-447b-b837-${new Date().getTime()}`;
        postRequest(`/boards/create`, {uid: uid, name: 'Untitled'})
            .then(res => {
                const newBoard = {
                    ctime: 0,
                    mtime: 0,
                    name: 'Untitled',
                    owner: '',
                    uid: uid,
                    units: []
                }
                setBoards([...boards, newBoard]);
                setBoardsIndex(boards.length);
                localStorage.setItem('uid', uid);
                localStorage.setItem('boardsIndex', boards.length.toString());
                setActiveNav(uid);
                setLayout([]);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }
    const navClick = (id:string, index: number) => {
        localStorage.setItem('uid', id);
        localStorage.setItem('boardsIndex', index.toString());
        setBoardsIndex(index);
        let layoutData: ChartPositionSize[] = [];
        boards[index].units.map(item => {
            layoutData.push({
                x: item.left,
                y: item.top,
                i: item.uid,
                w: item.width,
                h: item.height * 2
            })
        })
        setLayout(layoutData);
        setActiveNav(id)
    }
    const move = (direction: 'left'|'right') => {
        if (!scrollX.current) return
        scrollX.current.scrollTo({
            left: direction === 'left' ? (scrollX.current.scrollLeft - 100) : (scrollX.current.scrollLeft + 100),
            behavior: 'smooth'
        })
    }

    const handleDelete = (e: any, uid: string) => {
        e.stopPropagation()
        deleteBoards(uid);
    }
    return (
        <>
            <Header ref={header} />
            <div className={styles.container}>
                <div className={styles.tipsEditWrap}></div>
                <div className={styles.navs_wrap}>
                    <div className={styles.navs_left}>
                        <div className={styles.navs_items} ref={scrollX}>
                            {
                                boards && boards.map((it, index) => {
                                    return <div key={index}>
                                        {
                                            it.uid === 'home' || it.name === 'default' ? <div
                                                className={classnames([styles.navs_home, activeNav === 'home' && styles.navs_active])}
                                                onClick={() => navClick('home', 0)}>
                                                <img src={homeIcon} className={styles.home_icon} alt=""/>
                                                <p>Home</p>
                                            </div> :
                                                <div
                                                  className={classnames( [styles.nav_item, activeNav === it.uid && styles.navs_active])}
                                                  contentEditable={activeNav === it.uid && canEdit}
                                                  suppressContentEditableWarning={true}
                                                  onBlur={(e) => updateBoards(e.target.innerText)
                                                  }
                                                  onClick={() => navClick(it.uid, index)}>
                                                    {it.name}
                                                    {canEdit && <div className={styles.delWrap}>
                                                        <img onClick={(e,) => handleDelete(e, it.uid)} src={deleteICon}
                                                             className={styles.del} alt=""/>
                                                    </div>}
                                                </div>
                                        }
                                        </div>
                                })
                            }
                        </div>
                    </div>
                    <div className={styles.navs_right}>
                        {
                            scrollX.current && (scrollX.current.scrollWidth > scrollX.current.clientWidth) && <>
                                <div className={styles.toScroll} onClick={() => move('left')}>
                                    <Image preview={false} src={leftBlack}></Image>
                                </div>
                                <div className={classnames([styles.toScroll, styles.toScroll2])}
                                     onClick={() => move('right')}>
                                    <Image preview={false} src={rightBlack}></Image>
                                </div>
                            </>
                        }
                        <div className={styles.addWrap} onClick={addDashBoard}>
                            <Image preview={false} className={styles.editIcon} src={addWhite}></Image>
                            <div className={styles.addText}>NEW DASHBOARD</div>
                        </div>
                        {
                            activeNav !== 'home' &&
                            <div className={classnames([styles.editWrap, canEdit && styles.editActiveWrap])}
                                 onClick={editHandle}>
                                <Image preview={false} className={styles.editIcon} src={!canEdit ? editIcon : change}></Image>
                                <div className={classnames([styles.editText, canEdit && styles.editActiveText])}>{!canEdit? 'EDIT MODE' : 'VIEW MODE'}</div>
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.layoutWrap}>
                    <div className={styles.bgWrap} ref={bgWrap}>
                        {
                            new Array(activeNav === 'home' ? 24 : layoutArr).fill(1).map((item, index) => (
                                <div className={styles.bgItem} key={index} onClick={() => addHandle(index)}>
                                    <Image preview={false} className={styles.addImg} src={noSelectAdd}></Image>
                                </div>))
                        }
                    </div>
                    <div className={styles.layoutContent} ref={layoutContent}>
                        {
                            layout && layout.length > 0 && <GridLayout
                                className="layout"
                                layout={layout}
                                cols={6}
                                rowHeight={97}
                                onLayoutChange={onLayoutChange}
                                onDragStop={onDragStop}
                                isDraggable={canEdit}
                                width={screenWidth}
                            >
                                {boards && boards.length > 0 && boards[boardsIndex] && boards[boardsIndex].units.map((item, index) => (
                                    <div className={styles.item} key={item.uid}>
                                        {canEdit && activeNav !== 'home' && <div style={{marginTop: item.type === Type.Tracking ? '-4px' : '2px'}}
                                                 className={styles.editWrap}
                                                 onMouseDown={() => editUnit(item)}>
                                                <Image preview={false} className={styles.editICon}
                                                       src={edit}></Image>
                                            </div>}
                                        {canEdit && activeNav !== 'home' && <div style={{marginTop: item.type === Type.Tracking ? '-4px' : '2px'}}
                                                className={styles.removeWrap}
                                                onMouseDown={() => onRemoveUtils(item.uid, index)}>
                                            <Image preview={false} className={styles.deleteICon}
                                                    src={deleteICon}></Image>
                                        </div>}
                                        {chartDom(item)}
                                    </div>))
                                }
                            </GridLayout>
                        }
                    </div>
                </div>
                <SelectContext.Provider
                    value={{
                        x: position.x,
                        y: position.y,
                        type: activeNav,
                        unitEditUid: unitEditUid,
                        unitEditWidth: unitEditWidth,
                        unitEditHeight: unitEditHeight,
                        unitEditType: unitEditType,
                        unitEditData: unitEditData,
                        getSelectData: getSelectData
                    }}
                >
                    <SelectGroupModel unitEditType={unitEditType} isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}></SelectGroupModel>
                </SelectContext.Provider>
            </div>
            <Footer />
            {/*<DModal ref={DModalRef} onChange={onChange}></DModal>*/}
        </>
    );
};

export default ObservationGroup;