import styles from './index.module.scss';
import React, {useEffect, useState} from "react";
import {Image, Spin} from "antd";
import classnames from "classnames";
import {getRequest} from "../../helper/request";
import {Exchange} from "./Tracking";
import {formatNumber} from "../../utils/format";
interface LatestTickers{
    price: string;
    priceChangePercent: number;
    quoteVolume: string;
}
const TokenPrice = (props: {selectCoin: Exchange, page?: string, width?: number, height?: number}) => {
    const [ticker, setTicker] = useState<LatestTickers[]>([]);
    const isHome = props.page === 'home';
    useEffect(() => {
        if(!props.selectCoin.slug) return
        getUnlockEventsRequest(props.selectCoin.slug)
    }, [props.selectCoin.slug]);
    const getUnlockEventsRequest = (slug: string) => {
        getRequest(`/tokens/${slug}/latestTickers`, {params: {exchange: 'ALL'}})
            .then(res => {
                const data = res[0] as unknown as LatestTickers[];
                console.log(data)
                setTicker(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    function getFormatVal(val: number) {
        return Number(Math.floor(val)).toLocaleString('en');
    }
    const formatPriceNumber = (price: number) => {
        if(price > 10000) {
            return getFormatVal(price);
        }
        if(price > 1) {
            return Number(Number(price).toFixed(2)).toLocaleString('en');
        }
        if(price > 0.001) {
            return Number(price).toFixed(3);
        }
        return formatNumber(Number(price).toFixed(4).toString());
    }
    return (
        <div className={classnames(styles.tokenPriceWrap, isHome && styles.homeTokenPriceWrap)}
             style={{width: props.width + 'px', height: props.height + 'px'}}>
            {props.selectCoin && !props.selectCoin.slug && <div className={styles.priceNoData}>Complete configuration to see a preview.</div>}
            {(!ticker || !ticker[0] || !ticker[0].price) && props.selectCoin.slug && <div className={classnames(styles.spinWrap, isHome && styles.homeSpinWrap)}><Spin tip="Loading..." size="large" /></div>}
            {
                ticker && ticker[0] && ticker[0].price && <>
                    <div className={classnames(styles.tokenPriceInfo, isHome && styles.homeTokenPriceInfo)}>
                        <div className={classnames(styles.logoWrap, isHome && styles.homeLogoWrap)}>
                            {props.selectCoin.logo && <Image preview={false} className={styles.logoImg} src={props.selectCoin.logo}></Image>}
                        </div>
                        <div className={classnames(styles.infoWrap, isHome && styles.homeInfoWrap)}>
                            <div className={classnames(styles.coinName, isHome && styles.homeCoinName)}>{props.selectCoin.name}</div>
                            <div className={classnames(styles.coinMoney, isHome && styles.homeCoinMoney)}>
                                <div className={classnames(styles.money, isHome && styles.homeMoney)}>${ticker[0] && formatPriceNumber(+ticker[0].price)}</div>
                                <div className={classnames(styles.rate, isHome && styles.homeRate, ticker[0]?.priceChangePercent > 0 ? styles.up : styles.down)}>
                                    { ticker[0]?.priceChangePercent > 0 && '+' }{ticker[0] && Number(ticker[0].priceChangePercent).toFixed(2)}%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classnames(styles.tokenPriceVolume, isHome && styles.homeTokenPriceVolume)}>
                        <div className={classnames(styles.tokenPriceVolumeTitle, isHome && styles.homeTokenPriceVolumeTitle)}>volume(24H):</div>
                        <div className={classnames(styles.tokenPriceVolumeMoney, isHome && styles.homeTokenPriceVolumeMoney)}>${ticker[0] && Number(formatNumber(ticker[0].quoteVolume)).toLocaleString('en')}</div>
                    </div>
                </>
            }
        </div>
    );
};

export default TokenPrice;