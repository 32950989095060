import styles from "./index.module.scss"
import Header from "../components/header";
import Footer from "../components/footer";
import far from "./images/far.png"
import hj from "./images/hj.png"
import award from "./images/award.png"
import join from "./images/join.png"
import record from "./images/record.png"
import selected from "./images/selected.png"
import bot from "./images/bot.png"
import noLike from "./images/noLike.png"
import banner_left from "./images/banner_left.png"
import banner_right from "./images/banner_right.png"
import binance from "./images/binance.svg"
import bitget from "./images/bitget.svg"
import gate from "./images/gate.io.svg"
import kucoin from "./images/kucoin.svg"
import lbank from "./images/lbank.svg"
import mexc from "./images/mexc.svg"
import uniswap from "./images/uniswap.svg"
import upbit from "./images/upbit.svg"
import bg from "./images/bg.png"
import classnames from "classnames";
import type {TabsProps} from 'antd';
import {Carousel, Image, Table, TablePaginationConfig, Tabs} from 'antd';
import {useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {CarouselRef} from "antd/es/carousel";
import {getRequest, postRequest} from "../helper/request";
import {formatNumber} from "../utils/format";

const imgs:ExchangeMap = {
  binance,
  bitget,
  gate,
  kucoin,
  lbank,
  mexc,
  uniswap,
  upbit,
}
const items: TabsProps['items'] = [
  {
    key: 'All',
    label: 'All',
    children: '',
  },
  {
    key: 'DeFi',
    label: 'DeFi',
    children: '',
  },
  {
    key: 'DAO',
    label: 'DAO',
    children: '',
  },{
    key: 'Blockchain',
    label: 'Blockchain',
    children: '',
  },{
    key: 'Game',
    label: 'Game',
    children: '',
  },{
    key: 'CEX',
    label: 'CEX',
    children: '',
  },
];

interface LaunchpadDataType {
  categories: [];
  circulatingSupply: string;
  description: string;
  exchanges: Exchange[];
  fees: string;
  isLaunchpad: boolean;
  listingTime: number;
  logo: string;
  maxSupply: string;
  name: string;
  revenue: string;
  slug: string;
  symbol: string;
  totalRaised: string;
  totalValueLocked: string;
  treasury: string;
}

export interface TwoThreeDataType {
  categories: string[];
  description: string;
  fees: string;
  isNewListing: boolean;
  isPerpetual: boolean;
  listingTime: number;
  logo: string;
  name: string;
  price: string;
  priceChangePercent: number;
  quoteVolume: string;
  revenue: string;
  slug: string;
  symbol: string;
  totalRaised: string;
  totalValueLocked: string;
  treasury: string;
  volume: string;
  depthUsdNegativeTwo: string;
  depthUsdPositiveTwo: string;
  buyOrdersAmount: string;
  sellOrdersAmount: string;
}
interface Exchange{
  name: string,
  pair: string,
  listingTime: number
}
interface Launchpad {
  slug: string,
  logo: string,
  name: string,
  symbol: string,
  exchanges: Exchange[],
  listingTime: number
}

interface NewListing{
  slug: string,
  logo: string,
  name: string,
  symbol: string,
  price: string,
  priceChangePercent: number
}

interface Trending{
  slug: string,
  logo: string,
  name: string,
  symbol: string,
  price: string,
  priceChangePercent: number
}

interface Columns{
  title: string,
  dataIndex: string,
  key: string
}

interface Lines {
  volumeChangePercent24H: string;
}

interface ExchangeMap {
  [key: string]: string;
}
export enum Type {
  Favorites = 'favorites',
  Trending = 'trending',
  NewListing = 'newlist',
  Launchpad = 'launchpad'
}

interface Oracle{
  title: string;
  message: string;
}

type TablePaginationPosition = NonNullable<TablePaginationConfig['position']>[number];

const Home = () => {
  const navigate = useNavigate();
  const carouselRef = useRef<CarouselRef>(null)
  const [active, setActive] = useState(Type.Trending);
  const [oracles, setOracles] = useState<Oracle[]>();
  const [launchpad, setLaunchpad] = useState<Launchpad[]>([]);
  const [newListing, setNewListing] = useState<NewListing[]>([]);
  const [trending, setTrending] = useState<Trending[]>([]);
  const [launchpadData, setLaunchpadData] = useState<LaunchpadDataType[]>([]);
  const [twoThreeData, setTwoThreeData] = useState<TwoThreeDataType[]>([]);
  const [columns, setColumns] = useState<Columns[]>();
  const [categories, setCategories] = useState<string>();
  const [openModel, setOpenModel] = useState<boolean>();
  useEffect(() => {
    document.body.addEventListener('click', () => {
      setOpenModel(false);
    })
    launchpadRequest();
    newListingRequest();
    trendingRequest();
    oraclesRequest();
    allLaunchpadRequest();
    setColumns(launchpadColumns);

  }, []);
  useEffect(() => {
    const curr = localStorage.getItem('active');
    activeHandle(curr as Type || Type.Launchpad);
  }, [categories]);
  const launchpadColumns = [{
    title: 'Name',
    width: 100,
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    render: (_:any, item:any) => {
      return <div className={styles.nameWrap}>
        <img className={styles.nameIcon} src={item.logo} alt=""/>
        <p className={styles.name}>{item.symbol}</p>
        (<p className={styles.name}>{item.name}</p>)
      </div>
    },
  }, {
    title: 'Issue Price',
    width: 300,
    dataIndex: 'Issue Price',
    key: 'price',
    align: 'right',
    render: () => {
      return <span className={styles.cell200} >--</span>
    },
  },{
    title: 'Total Supply',
    dataIndex: 'maxSupply',
    key: 'maxSupply',
    align: 'right',
    render: (_:any, it:any) => {
        return <div>{getFormatVal(it.maxSupply)} TIA</div>
    },
  },{
    title: 'Cir.Supply',
    dataIndex: 'circulatingSupply',
    key: 'circulatingSupply',
    align: 'right',
    render: () => {
      return <span className={styles.cell200} >--</span>
    },
  },{
    title: 'Next 7D Emission',
    dataIndex: 'Next 7D Emission',
    key: 'Next 7D Emission',
    align: 'right',
    render: () => {
      return <span className={styles.cell200} >--</span>
    },
  },{
    title: 'Exchange',
    dataIndex: 'Exchange',
    key: 'Exchange',
    align: 'right',
    render: (_:any, it:any) => {
        return <div className={styles.home_card_item_r_imgs}>
        { it.exchanges && it.exchanges.map((items: Exchange) => <img key={items.name} src={imgs[items.name.toLowerCase()]} alt=""/>) }
        {it.exchanges && it.exchanges.length > 3 && <div className={styles.home_card_item_r_imgs_total}>+{it.exchanges && it.exchanges.length - 3}</div>}
      </div>
    },
  },{
    title: 'Listing Time',
    dataIndex: 'Listing Time',
    key: 'Listing Time',
    align: 'right',
    render: (_:any, it:any) => {
        return <div>{convertTimestampToDuration(it.listingTime)}</div>
    },
  }]


  const twoThreeColumns = [
    // {
    //   title: ' ',
    //   width: 40,
    //   dataIndex: 'like',
    //   key: 'like',
    //   align: 'left',
    //   fixed: 'left',
    //   render: (_:any, item:any) => {
    //     return <img className={styles.likeIcon} src={noLike} alt="" onClick={likedHandle}/>
    //   },
    // },
    {
      title: 'Name',
      width: 240,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      render: (_:any, item:any) => {
        return <div className={styles.nameWrap} >
                <img className={styles.nameIcon} src={item.logo} alt=""/>
                <p className={styles.name}>{item.name}</p>
                (<p className={styles.name}>{item.symbol}</p>)
              </div>
      },
    },
    {
      title: 'Price(24H %)',
      width: 300,
      dataIndex: 'priceChangePercent',
      key: 'priceChangePercent',
      align: 'right',
      render: (_:any, item:any) => {
        return <span className={styles.cell200} > ${item.price > 1 ? Number(item.price).toFixed(2) : formatNumber(item.price.toString())}
                <span className={item.priceChangePercent > 0 ? styles.up : styles.down}>({item.priceChangePercent > 0 && '+'}{Number(item.priceChangePercent).toFixed(2)}%)</span>
              </span>
      },
    },
    {
      title: 'Volume(24H %)',
      width: 300,
      dataIndex: 'quoteVolume',
      key: 'quoteVolume',
      align: 'right',
      render: (_:any, item:any) => {
        return <span className={styles.cell200} >${getFormatVal(item.quoteVolume)}
                <span className={item.volumeChangePercent24H > 0 ? styles.up : styles.down}>({item.volumeChangePercent24H > 0 && '+'}{Number(item.volumeChangePercent24H).toFixed(2)}%)</span>
              </span>
      },
    },
    {
      title: '+2%/-2% Depth',
      width: 240,
      key: 'depthUsdPositiveTwo',
      dataIndex: 'depthUsdPositiveTwo',
      align: 'right',
      render: (_:any, item:any) => {
        return <span className={styles.cell200} >${getFormatVal(+item.depthUsdPositiveTwo)}/$ {getFormatVal(+item.depthUsdNegativeTwo)}</span>
      },
    },
    {
      title: 'Buy/Sell',
      width: 200,
      key: 'Buy/Sell',
      dataIndex: 'Buy/Sell',
      align: 'right',
      render: (_:any, item:any) => {
        const buyOrdersPercent = +Number(+item.buyOrdersAmount/(+item.buyOrdersAmount + +item.sellOrdersAmount) * 100).toFixed(2);
        const sellOrdersPercent = +Number(100 - buyOrdersPercent).toFixed(2);
        return <div className={styles.percentWrap} >
          <div className={styles.percentItem}>
            <div className={styles.percent}>{buyOrdersPercent}%</div>
            <div className={styles.percent}>{sellOrdersPercent}%</div>
          </div>
          <div className={styles.percentItem}>
            <div className={styles.buyOrdersProgress} style={{width: buyOrdersPercent + '%'}}></div>
            <div className={styles.sellOrdersProgress} style={{width: sellOrdersPercent + '%'}}></div>
          </div>
        </div>
      },
    },
    {
      title: 'Liquidity',
      width: 200,
      dataIndex: 'Liquidity',
      key: 'Liquidity',
      align: 'right',
      render: () => {
        return <span className={styles.cell200} >--</span>
      },
    },
    {
      title: 'Cir.Supply',
      width: 200,
      dataIndex: 'Cir.Supply',
      key: 'Cir.Supply',
      align: 'right',
      render: () => {
        return <span className={styles.cell200} >--</span>
      },
    },
    {
      title: 'Next 7D Emission',
      width: 200,
      dataIndex: 'Next 7D Emission',
      key: 'Next 7D Emission',
      align: 'right',
      render: () => {
        return <span className={styles.cell200} >--</span>
      },
    },
  ];

  const buildLaunchpad = (it: Launchpad) => {
    return (
      <div className={styles.home_card_item} key={it.name}>
        <div className={styles.home_card_item_l}>
          <img src={it.logo} alt=""/>
          <p onClick={() => toDetail(it.slug)}>{it.symbol}</p>
          (<p>{it.name}</p>)
        </div>
        <div className={styles.home_card_item_r}>
          <div className={styles.home_card_item_r_imgs}>
            {
              it.exchanges && it.exchanges.map(item => {
                return <img key={item.name} src={imgs[item.name.toLowerCase()]} alt=""/>
                })
            }
            {it.exchanges.length > 3 && <div className={styles.home_card_item_r_imgs_total}>+{it.exchanges && it.exchanges.length - 3}</div>}
          </div>
          <div className={styles.home_card_item_r_text}>{formatTimestampToDate(+it.listingTime)}</div>
        </div>
      </div>
    )
  }

  const buildNewList = (it: NewListing) => {
    return (
      <div className={styles.home_card_item} key={it.price}>
        <div className={styles.home_card_item_l}>
          <img src={it.logo} alt=""/>
          <p onClick={() => toDetail(it.slug)}>{it.name}</p>
          (<p>{it.symbol}</p>)
        </div>
        <div className={styles.home_card_item_r}>
          <div className={styles.home_card_item_r_text}>$ {(it.price && Number(Number(it.price).toFixed(+it.price > 1 ? 2 : 4)).toLocaleString('en')) || '--'}</div>
          <div className={classnames([styles.home_card_item_r_text, it.priceChangePercent > 0 ? styles.up : styles.down])}>
            ({it.priceChangePercent > 0 && '+'}{(it.priceChangePercent && it.priceChangePercent.toFixed(2)) || '--'}%)
          </div>
        </div>
      </div>
    )
  }

  const buildTrending = (it: Trending) => {
    return (
      <div className={styles.home_card_item} key={it.price}>
        <div className={styles.home_card_item_l}>
          <img src={it.logo} alt=""/>
          <p onClick={() => toDetail(it.slug)}>{it.name}</p>
          (<p>{it.symbol}</p>)
        </div>
        <div className={styles.home_card_item_r}>
          <div className={styles.home_card_item_r_text}>$ {Number(formatNumber(it.price)).toLocaleString('en')}</div>
          <div className={classnames([styles.home_card_item_r_text, it.priceChangePercent > 0 ? styles.up : styles.down])}>
            ({it.priceChangePercent > 0 && '+'}{it.priceChangePercent.toFixed(2)}%)
          </div>
        </div>
      </div>
    )
  }

  const likedHandle = () => {

  }

  const prevBanner = () => {
    carouselRef.current && carouselRef.current.prev()
  }

  const nextBanner = () => {
    carouselRef.current && carouselRef.current.next()
  }

  const buildActiveTab = (activeKey: string, nowKey:string) => {
    if (activeKey === nowKey) {
      return [styles.tab, styles.tab_active]
    }
    return [styles.tab]
  }

  const onChange = (key: string) => {
    setCategories(key);
  };
  const launchpadRequest = () => {
    postRequest(`/tokens?withExchanges=true`, {where: {isLaunchpad: true},sort: {listingTime: 1},limit: 3})
        .then(res => {
          const data = res[0] as unknown as Launchpad[];
          setLaunchpad(data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
  };
  const newListingRequest = () => {
    postRequest(`/tokens`, {where: {isNewListing: true},sort: {quoteVolume: -1},limit: 3})
        .then(res => {
          const data = res[0] as unknown as NewListing[];
          setNewListing(data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
  };
  const trendingRequest = () => {
    postRequest(`/tokens`, {where: {isTrending: true},sort: {priceChangePercent: -1},limit: 3})
        .then(res => {
          console.log(res);
          const data = res[0] as unknown as Trending[];
          setTrending(data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
  };
  const oraclesRequest = () => {
    postRequest(`/ai-oracles`)
        .then(res => {
          const data = res[0] as unknown as Oracle[];
          setOracles(data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
  };
  const allLaunchpadRequest = () => {
    postRequest(`/tokens?withExchanges=true`, {where: categories ? {isLaunchpad: true, categories: categories} : {isLaunchpad: true},sort: {listingTime: 1}})
        .then(res => {
          const data = res[0] as unknown as LaunchpadDataType[];
          setLaunchpadData(data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
  };
  const allNewListingRequest = () => {
    postRequest(`/tokens`, {where: categories ? {isNewListing: true, categories: categories} : {isNewListing: true}})
        .then(async res => {
          const data = res[0] as unknown as TwoThreeDataType[];
          let datas:TwoThreeDataType[] = [];
          if(data.length === 0) {
            setTwoThreeData([]);
          }
          await Promise.all(
            data.map(async (item, index) => {
              const it: any = await latestTickersRequest(item);
              const its: any = await getLinesRequest(it);
              datas.push(its);
            })
          );
          setTwoThreeData(datas);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
  };
  const allTrendingRequest = () => {
    postRequest(`/tokens`, {where: categories ? {isTrending: true, categories: categories} : {isTrending: true}})
        .then(async res => {
          const data = res[0] as unknown as TwoThreeDataType[];
          let datas:TwoThreeDataType[] = [];
          if(data.length === 0) {
            setTwoThreeData([]);
          }
          await Promise.all(
            data.map(async (item, index) => {
              const it: any = await latestTickersRequest(item);
              const its: any = await getLinesRequest(it);
              datas.push(its);
            })
          );
          setTwoThreeData(datas);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
  };
  const getLinesRequest =  (it: any) => {
    return new Promise((resolve, reject) => {
        getRequest(`/tokens/${it.slug}/latestKlines`)
        .then( res => {
            const data = res[0] as unknown as Lines[];
          it.volumeChangePercent24H = data[0].volumeChangePercent24H;
            resolve(it);
        })
        .catch(error => {
            console.error('Error fetching data', error);
        });
    })
}
  const latestTickersRequest = (it: TwoThreeDataType) => {
    return new Promise((resolve, reject) => {
      getRequest(`/tokens/${it.slug}/latestTickers?exchange=ALL`)
        .then(res => {
          const data = res[0] as unknown as TwoThreeDataType[];
          it.depthUsdPositiveTwo = data[0].depthUsdPositiveTwo;
          it.depthUsdNegativeTwo = data[0].depthUsdNegativeTwo;
          it.buyOrdersAmount = data[0].buyOrdersAmount;
          it.sellOrdersAmount = data[0].sellOrdersAmount;
          resolve(it);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    })
  };
  function formatTimestampToDate(timestamp: number) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const date = new Date(timestamp);
    const monthIndex = date.getMonth();
    const day = date.getDate();

    const month = months[monthIndex];

    return `${day} ${month}`;
  }
  function convertTimestampToDuration(timestamp: number) {
    const seconds = Math.floor((timestamp / 1000) % 60);
    const minutes = Math.floor((timestamp / (1000 * 60)) % 60);
    const hours = Math.floor((timestamp / (1000 * 60 * 60)) % 24);
    const days = Math.floor(timestamp / (1000 * 60 * 60 * 24));
  
    return `${days} Days ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  function getFormatVal(val: number) {
    return Number(Math.floor(val)).toLocaleString('en');
  }
  const activeHandle = (type: Type) => {
    localStorage.setItem('active', type);
    setColumns(type === Type.Launchpad ? launchpadColumns : twoThreeColumns);
    setActive(type);
    if(type === Type.Favorites) {
      setTwoThreeData([]);
      setLaunchpadData([]);
      return
    }
    type === Type.Launchpad ? allLaunchpadRequest() : type === Type.Trending ? allTrendingRequest() : allNewListingRequest();
  }
  const rowClickConfig = (record: TwoThreeDataType) => {
    navigate(`/detail`, {state: {active: record.slug}})
  };
  const launchpadRowClickConfig = (record: LaunchpadDataType) => {
    navigate(`/detail`, {state: {active: record.slug, type: 'launchpad'}})
  };
  const toDetail = (slug: string) => {
    navigate(`/detail`, {state: {active: slug}})
  };
  const openModelHandle = (e: any) => {
    e.stopPropagation();
    setOpenModel(!openModel);
  }
  return <>
    <Header/>
    <div className={styles.home_wrap}>
      <div className={styles.home_container}>
        <div className={styles.home_title}>Token tracking tool</div>
        <div className={styles.home_small_title}>The Best Token Unlock Schedule & Intense Tokenomics Data Is Here.</div>
        <div className={styles.home_card_wrap}>
          <div className={styles.home_card}>
            <div className={styles.home_card_title}>
              <img className={styles.far} src={far} alt=""/>
              <p>Launchpad</p>
            </div>
            {
              launchpad && launchpad.map(it => {
                return buildLaunchpad(it)
              })
            }

          </div>
          <div className={styles.home_card}>
              <div className={styles.home_card_title}>
                <img src={hj} alt=""/>
                <p>New Listing</p>
              </div>
              {
                  newListing && newListing.map(it => {
                  return buildNewList(it)
                })
              }
          </div>
          <div className={styles.home_card}>
            <div className={styles.home_card_title}>
              <img src={award} alt=""/>
              <p>Trending</p>
            </div>
            {
                trending && trending.map(it => {
                return buildTrending(it)
              })
            }
          </div>
          <div className={styles.home_card}>
            <Image preview={false} className={styles.bg_img} src={bg}></Image>
            <Carousel ref={carouselRef}>
              {oracles?.map(item => (<div className={styles.banner} key={item.title}>
                <div className={styles.title}>{item.title}</div>
                <div className={styles.content}>{item.message}</div>
              </div>))}
            </Carousel>
            <img className={styles.banner_left} src={banner_left} onClick={prevBanner} alt="" />
            <img className={styles.banner_right} src={banner_right} onClick={nextBanner} alt="" />
            <div className={styles.btn_wrap}>
              <div className={styles.btn_left}>
                <img src={join} alt=""/>
                <p>Join Now</p>
              </div>
              {/*<div className={styles.btn_right}>*/}
              {/*  <img src={bot} alt=""/>*/}
              {/*  <p>Dexbro Bot</p>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        <div className={styles.tab_module}>
          <div className={classnames(buildActiveTab(active, 'favorites'))} onClick={() => activeHandle(Type.Favorites)}>Favorites</div>
          <div className={classnames(buildActiveTab(active, 'trending'))} onClick={() => activeHandle(Type.Trending)}>Trending</div>
          <div className={classnames(buildActiveTab(active, 'newlist'))} onClick={() => activeHandle(Type.NewListing)}>New Listing</div>
          <div className={classnames(buildActiveTab(active, 'launchpad'))} onClick={() => activeHandle(Type.Launchpad)}>Launchpad</div>
        </div>
        <div className={styles.list}>
          <div className={styles.tab_wrap}>
            <Tabs defaultActiveKey="1" items={items} indicatorSize={20} onChange={onChange} />
          </div>
          <Image className={styles.record} src={record} preview={false} onClick={openModelHandle}></Image>
          {
            openModel && <div className={styles.record_model_wrap}>
              <div className={styles.record_model_title}>EXCHANGES</div>
              <div className={styles.radioRightWrap}>
                  <Image preview={false} className={styles.selectImg} src={selected}></Image>
                  <div className={styles.radioIcon}>
                      <Image preview={false} className={styles.coinImg} src={binance}></Image>
                  </div>
                  <div className={styles.radioText}>Binance</div>
              </div>
              <div className={styles.radioRightWrap}>
                  <Image preview={false} className={styles.selectImg} src={selected}></Image>
                  <div className={styles.radioIcon}>
                      <Image preview={false} className={styles.coinImg} src={mexc}></Image>
                  </div>
                  <div className={styles.radioText}>MEXC</div>
              </div>
              <div className={styles.radioRightWrap}>
                  <Image preview={false} className={styles.selectImg} src={selected}></Image>
                  <div className={styles.radioIcon}>
                      <Image preview={false} className={styles.coinImg} src={lbank}></Image>
                  </div>
                  <div className={styles.radioText}>LBank</div>
              </div>
            </div>
          }
        </div>
        <div className={styles.table_wrap}>
          {active === Type.Launchpad ? <Table  onRow={(record: LaunchpadDataType) => ({onClick: () => launchpadRowClickConfig(record)})}
                                               style = {{cursor: 'pointer'}} columns={columns} dataSource={launchpadData} pagination={{ position: ["none", "bottomCenter"] }} />
          :<Table onRow={(record: TwoThreeDataType) => ({onClick: () => rowClickConfig(record)})} columns={columns} dataSource={twoThreeData}
                  style = {{cursor: 'pointer'}} pagination={{ position: ["none", "bottomCenter"] }} scroll={{ x: 1270 }} />}
        </div>
      </div>
    </div>
    <Footer/>
  </>
}

export default Home