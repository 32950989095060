import styles from "./index.module.scss"
import {useEffect, useRef} from "react";
import * as echarts from 'echarts';
import {Progress} from "../../index"
const Index = ({progress, total}: {progress: Progress, total: number}) => {
  const pieChart = useRef(null)

  useEffect(() => {
    initChart()
  }, [progress])

  const initChart = () => {
    const chart = echarts.init(pieChart.current)
    const option = {
      title: {
        text: 'Total Unlock Progress',
      },
      tooltip: {
        show: false,
        trigger: 'item',
        formatter: '{b} : {c}%'
      },
      graphic: [
        {
          type: 'text',
          left: 'center',
          top: 'center',
          style: {
            text: `${formatAmountPercent(+progress.unlockedAmount)}\n\nUnlocked`,
            fill: '#333',
            fontWeight: "bolder",
            fontSize: 16,
            textAlign: 'center',
            textVerticalAlign: 'middle'
          },
        },
      ],
      color: ['#F93435', '#23C448', '#58667E'],
      legend: {
        show: false,
        top: '5%',
        left: 'center'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['60%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
            fontSize: 16,
          },
          emphasis: {
            scale: true,
            label: {
              show: false,
              fontSize: 16,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: true
          },
          data: [
            { value: ((+progress.lockedAmount/total) * 100).toFixed(2), name: `Locked` },
            { value: ((+progress.unlockedAmount/total) * 100).toFixed(2), name: 'Unlocked' },
            { value: ((+progress.untrackedAmount/total) * 100).toFixed(2), name: 'Untracked' }
          ]
        }
      ]
    }
    option && chart.setOption(option)
    chart.on('mouseover', function (params:any) {
      if (!params.data) return
      const name = params.data.name.length < 12 ? params.data.name : params.data.name.substring(0, 12) + '...'
      chart.setOption({
        graphic: [
          {
            type: 'text',
            left: 'center',
            top: 'center',
            style: {
              text: `${params.data.value}%\n\n${name}`,
              fill: '#333',
              fontWeight: "bolder",
              fontSize: 16,
              textAlign: 'center',
              textVerticalAlign: 'middle'
            },
          },
        ],
      });
    });
  }
  const formatAmountPercent = (val: number) => {
    if(val === 0 || isNaN(val)) {
      return '--'
    }
    return (val/+(+progress.lockedAmount + +progress.unlockedAmount
        + +progress.untrackedAmount) * 100).toFixed(2) + '%'
  }
  return <div className={styles.pie_wrap} ref={pieChart}></div>
}

export default Index