import React from 'react';
import {Route, HashRouter as Router, Routes} from "react-router-dom";
import Home from "./Home";
import Detail from "./Detail";
import Dashboard from "./Dashboard";
import Bots from "./Bots";
import Profile from "./Profile";
function App() {
  return (
      <Router>
          <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/home" element={<Home />}></Route>
              <Route path="/detail" element={<Detail />}></Route>
              <Route path="/dashboard" element={<Dashboard />}></Route>
              <Route path="/bots" element={<Bots />}></Route>
              <Route path="/Profile" element={<Profile />}></Route>
          </Routes>
      </Router>
  );
}

export default App;
