import styles from "./index.module.scss"
import Header from "../components/header";
import Footer from "../components/footer";
const Bots = () => {
    return <>
        <Header />
        <div className={styles.container}>
            bots
        </div>
        <Footer /></>
}

export default Bots