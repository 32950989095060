import React, {useContext, useState, useRef, useEffect, memo, useCallback} from 'react';
import {Carousel, Image} from 'antd';
import TrackingChart from "./TrackingChart";
import TokenPrice from "./TokenPrice";
import UpcomingUnlock from "./UpcomingUnlock";
import Upcoming from "./Upcoming";
import styles from './index.module.scss';
import classnames from 'classnames';
import {UnitType} from "./SelectUnit";
import {postRequest} from "../../helper/request";
import {Exchange} from "./Tracking";
import {EditUnitType, SelectContext} from '../index';
interface CarouselRef {
    next: () => void;
    prev: () => void;
    goTo: (slide: number) => void;
}
export interface Locks{
    allocations:[]
    cliffUnlockAmount: string
    linearUnlockAmountPerDay: string
    linearUnlockDuration: number
    type: string
    unlockTime: number
}
interface Props {
    isShowPrice: boolean,
    isShowVolume: boolean,
    selectCoin: Exchange,
    type: UnitType,
    getSize: (w: number, h: number) => void
}
const ImgFormCarousel = (props: Props) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const carouselRef = useRef<CarouselRef>(null);
    const sizeWidthData = props.type === UnitType.Tracking ? [3, 4] : [1, 1, 2];
    const sizeHeightData = props.type === UnitType.Tracking ? [2, 2] : [1, 2, 2];
    const [locks, setLocks] = useState<Locks[]>([]);
    const [sizeWidth, setSizeWidth] = useState(0);
    const SelectCtx = useContext(SelectContext);
    const onChange = (currentSlide: number) => {
        setCurrentSlide(currentSlide);
        props.getSize(sizeWidthData[currentSlide], sizeHeightData[currentSlide]);
    };
    useEffect(() => {
        if(SelectCtx.unitEditType === EditUnitType.TokenPrice){
            setSizeWidth(25);
            return
        }
        setTimeout(() => setCurrentSlide(SelectCtx.unitEditWidth === 3 ? 0 : 1), 100)
    }, [SelectCtx.unitEditType]);
    
    
    useEffect(() => {
        if(SelectCtx.unitEditType === EditUnitType.TokenPrice) return
        const size = (sizeWidthData[currentSlide] * 25) + (sizeWidthData[currentSlide] - 1) * 3;
        setSizeWidth(size);
        props.getSize(sizeWidthData[currentSlide], sizeHeightData[currentSlide]);
    }, [currentSlide]);

    useEffect(() => {
        onChange(0);
        props.type === UnitType.Allocations && getUnlockEventsRequest(props.selectCoin.slug);
        if (!props.selectCoin.exchangeName) {
            return
        }
    }, [props.selectCoin])
    const getUnlockEventsRequest = (slug: string) => {
        postRequest(`/tokens/${slug}/unlock/events`, {params: {slug: slug}})
            .then(res => {
                const data = res[0] as unknown as Locks[]
                setLocks(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    const chartDom = useCallback((type: UnitType, selectCoin: Exchange, slide: number) => {
        const map:Map<UnitType, () => JSX.Element> = new Map([
                [UnitType.Tracking, () => <TrackingChart isShowPrice={props.isShowPrice} isShowVolume={props.isShowVolume}
                                                        selectCoin={props.selectCoin} width={slide === 0 ? 538 : 720} height={230}/>],
                [UnitType.Allocations, () => <Carousel afterChange={onChange} ref={carouselRef as any}>
                    <div className={styles.upcomingUnlock}>
                        {locks.length > 0 ? <UpcomingUnlock locks={locks} width={180}/> : <div className={styles.noToken}>Select a token</div>}
                    </div>
                    <div className={styles.upcomingUnlock}>
                        {locks.length > 0 ? <Upcoming locks={locks} width={173} height={230} /> : <div className={styles.noToken}>Select a token</div>}
                    </div>
                    <div className={styles.upcomingUnlock}>
                        {locks.length > 0 ? <Upcoming locks={locks} width={173} height={230} /> : <div className={styles.noToken}>Select a token</div>}
                    </div>
                </Carousel>],
                [UnitType.TokenPrice, () => <div className={styles.upcomingUnlock}><TokenPrice selectCoin={selectCoin} /></div> 
            ],
        ])
        if (!map.has(type)) {
            return <TokenPrice selectCoin={selectCoin} />;
        }
        return map.get(type)!();
    }, [props.type, props.selectCoin, currentSlide, props.isShowPrice, props.isShowVolume])
    const mouseMoveHandle = (e: any) => {
        if(props.type !== UnitType.Tracking) {
            return
        }
        const element = document.querySelector(`.${styles.sizeList}`);
        const rect = element?.getBoundingClientRect() || {left: 0, top: 0, width: 0, height: 0};
        const elementWidth = rect.left + rect.width;
        const elementRight = rect.top + rect.height;
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        // 第三列
        if (mouseX > elementWidth - 120  && mouseX < elementWidth - 90) {
            setSizeWidth(80);
        }
        // 第四列
        if (mouseX > elementWidth - 90  && mouseX < elementWidth - 60 && mouseY < elementRight - 50) {
            setSizeWidth(108);
        }
    }
    const mouseDownHandle = (e: any) => {
        if(props.type !== UnitType.Tracking) {
            return
        }
        const element = document.querySelector(`.${styles.sizeList}`);
        const rect = element?.getBoundingClientRect() || {left: 0, top: 0, width: 0, height: 0};
        const elementWidth = rect.left + rect.width;
        const elementRight = rect.top + rect.height;
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        // 第三列
        if (mouseX > elementWidth - 120  && mouseX < elementWidth - 90) {
            setSizeWidth(80);
            setCurrentSlide(0);
        }
        // 第四列
        if (mouseX > elementWidth - 90  && mouseX < elementWidth - 60 && mouseY < elementRight - 50) {
            setSizeWidth(108);
            setCurrentSlide(1);
        }
    }
    const mouseLeaveHandle = () => {
        if(props.type !== UnitType.Tracking) {
            return
        }
        setSizeWidth(currentSlide === 0 ? 80 : 108);
    }
    return (
        <div className={styles.carouselContainer}>
            {chartDom(props.type, props.selectCoin, currentSlide)}
            <div className={styles.sizeWrap}>
                <div className={styles.sizeList} onMouseMove={mouseMoveHandle} onMouseDown={mouseDownHandle}>
                    {
                        new Array(18).fill(1).map((item, index) => (
                            <div className={classnames([styles.sizeItem, (index === 3 || index === 9) && props.type === UnitType.Tracking && styles.canSelectItem])} onClick={() => index === 9 && setCurrentSlide(1)}  key={index}></div>))
                    }
                    <div className={styles.currSize} style={{width: sizeWidth + 'px', height: ((sizeHeightData[currentSlide] * 17) + 
                    (sizeHeightData[currentSlide] - 1) * 3) + 'px'}} onMouseLeave={mouseLeaveHandle}></div>
                </div>
                <div className={styles.sizeText}>Size：{sizeWidthData[currentSlide]}*{sizeHeightData[currentSlide]}</div>
            </div>
        </div>
    );
};

export default memo(ImgFormCarousel);