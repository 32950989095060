import { Modal } from 'antd';
import React, {useState} from "react";
import styles from './index.module.scss';
import deleteIcon from '../image/deleteIcon.png';

const DeleteModel = (props: {isModalOpen: boolean, handleOk: () => void, handleCancel: () => void}) => {
    const onCancel = () => {
        props.handleCancel();
    }
    return (
        <Modal width={600}
               centered={true}
               open={props.isModalOpen}
               onOk={props.handleOk}
               onCancel={onCancel}
               footer={null}
               wrapClassName={styles.wrap}
        >
            <div className={styles.container}>
                <div className={styles.title}>Delete subscription</div>
                <div className={styles.infoWrap}>
                    <div className={styles.infoText}>Token Tracking</div>
                    <img src={deleteIcon} alt="" className={styles.deleteIcon}/>
                </div>
                <div className={styles.deleteBtn} onClick={props.handleOk}>Delete</div>
            </div>
        </Modal>
    );
};

export default DeleteModel;