import { Modal } from 'antd';
import React, {useEffect, useState} from "react";
import SelectUnit, {UnitType} from "./SelectUnit";
import Tracking from "./Tracking";
import styles from './index.module.scss';
import {EditUnitType} from '../index';
const SelectGroupModel = (props: {unitEditType: EditUnitType, isModalOpen: boolean, handleOk: () => void, handleCancel: () => void}) => {
    const [type, setType] = useState<UnitType>(UnitType.Unit);

    useEffect(() => {
        console.log(props.unitEditType);
        if(props.unitEditType === EditUnitType.Tracking) {
            setType(UnitType.Tracking);
        }
        if(props.unitEditType === EditUnitType.TokenPrice) {
            setType(UnitType.TokenPrice);
        }
    }, [props.unitEditType])

    const handleType = (type: UnitType) => {
        if(type === UnitType.Add) {
            setType(UnitType.Unit);
           return props.handleOk();
        }
        setType(type);
    }
    const onCancel = () => {
        setType(UnitType.Unit);
        props.handleCancel();
    }
    const chartDom = () => {
        console.log('传入type', type);
        const map:Map<UnitType, () => JSX.Element> = new Map([
            [UnitType.Unit, () => <SelectUnit clickUnit={handleType}></SelectUnit>],
            [UnitType.Tracking, () => <Tracking type={type} clickBackAdd={handleType}></Tracking>],
        ])
        if (!map.has(type)) {
            return <Tracking type={type} clickBackAdd={handleType}></Tracking>;
        }
        return map.get(type)!();
    }
    return (
        <Modal width={1340}
               centered={true}
               open={props.isModalOpen}
               onOk={props.handleOk}
               onCancel={onCancel}
               footer={null}
               wrapClassName={styles.wrap}
        >
            {chartDom()}
        </Modal>
    );
};

export default SelectGroupModel;