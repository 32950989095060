import styles from './index.module.scss';
import React, {useEffect} from "react";
import {Image} from "antd";
import classnames from "classnames";
import allocation from "./image/allocation.png";
import {Locks} from "./ImgFormCarousel";

const UpcomingUnlock = (props: {locks: Locks[], page?: string, width?: number, height?: number}) => {
    useEffect(() => {

    }, [props.locks]);
    return (
        <div className={classnames(styles.upcomingWrap, props.page === 'home' && styles.homeUpcomingWrap)}
             style={{width: props.width + 'px', height: props.height + 'px'}}>
            <div className={classnames(styles.upcomingTitle, props.page === 'home' && styles.homeUpcomingTitle)}>Upcoming Unlock</div>
            <div className={classnames(styles.upcomingList, props.page === 'home' && styles.homeUpcomingList)}>
                <div className={classnames(styles.upcomingLeftWrap, props.page === 'home' && styles.homeUpcomingLeftWrap)}>
                    <div className={classnames(styles.upcomingLeftIcon, props.page === 'home' && styles.homeUpcomingLeftIcon)}></div>
                    <div className={classnames(styles.upcomingLeftData, props.page === 'home' && styles.homeUpcomingLeftData)}>15.60m TIA(4.23%)</div>
                    <div className={classnames(styles.upcomingLeftMoney, props.page === 'home' && styles.homeUpcomingLeftMoney)}>
                        ${props.locks[1] && props.locks[1].linearUnlockAmountPerDay || '$23.25m'}
                    </div>
                </div>
                <div className={classnames(styles.upcomingRightWrap, props.page === 'home' && styles.homeUpcomingRightWrap)}>
                    <div className={styles.upcomingRightMain}>
                        <div className={classnames(styles.upcomingRightIcon, props.page === 'home' && styles.homeUpcomingRightIcon)}>
                            <Image preview={false} className={styles.upcomingRightIconImg} src={allocation}></Image>
                        </div>
                        <div className={classnames(styles.upcomingRightTitle, props.page === 'home' && styles.homeUpcomingRightTitle)}>Allocation</div>
                    </div>
                    <div className={classnames(styles.upcomingRightNumber, props.page === 'home' && styles.homeUpcomingRightNumber)}>6</div>
                </div>
            </div>
            <div className={classnames(styles.upcomingTime, props.page === 'home' && styles.homeUpcomingTime)}>2023-11-05 17:00:00</div>
        </div>
    );
};

export default UpcomingUnlock;